const { SET_SUMMARY, SET_ANALYTICS, SET_RECENT_ACTIVITY, SET_CLICK_DATA, SET_BOUNDS_DATA } = require("../actions/actions_dashboard");

const initialState = {
    summary : [],
    recentActivity : [],
    clickData : false,
    boundsData : false,
    analytics : false
}

const reducer_dashboard = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_SUMMARY:
            var newState = JSON.parse(JSON.stringify(state))
            newState.summary = payload;
            return newState
        case SET_CLICK_DATA:
            var newState = JSON.parse(JSON.stringify(state))
            newState.clickData = payload;
            return newState
        case SET_BOUNDS_DATA:
            var newState = JSON.parse(JSON.stringify(state))
            newState.boundsData = payload;
            return newState
        case SET_RECENT_ACTIVITY:
            var newState = JSON.parse(JSON.stringify(state))
            newState.recentActivity = payload;
            return newState
        case SET_ANALYTICS:
            var newState = JSON.parse(JSON.stringify(state))
            newState.analytics = payload;
            return newState
        default:
            return state
    }
}
export default reducer_dashboard;
