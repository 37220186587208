import React from 'react';
import Flatpickr from 'react-flatpickr';

import { connect } from 'react-redux';
import { setDateRange } from '../actions/actions_date';

function Datepicker({
  dispatch,
  align,
  startTime,
  endTime
}) {

  let defaultDate = startTime === '' && endTime === '' ? [new Date().setDate(new Date().getDate() - 1), new Date()] : [new Date(startTime), new Date(endTime)]
  const lastTimeDatePicked = localStorage.getItem("dateTimePicker");
  if(lastTimeDatePicked){
    const {newStartTime, newEndTime, timeDatesPicked} = JSON.parse(lastTimeDatePicked)
    const dateTimeNow = new Date();
    const dateTimePicked = new Date(timeDatesPicked);
    if(dateTimeNow.getTime() - dateTimePicked.getTime() < 3600000){
      const newDefault = [new Date(newStartTime).setDate(new Date(newStartTime).getDate() + 1), new Date(newEndTime).setDate(new Date(newEndTime).getDate() + 1)]
      defaultDate = newDefault
      const setStartTime = new Date(newDefault[0]).toLocaleDateString('en-CA')
      const setEndTime = new Date(newDefault[1]).toLocaleDateString('en-CA')
      dispatch(setDateRange({startTime : setStartTime, endTime : setEndTime }))
    }
  }



  const options = {
    mode: 'range',
    static: true,
    monthSelectorType: 'static',
    dateFormat: 'M j, Y',
    defaultDate: defaultDate,
    prevArrow: '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
    nextArrow: '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    onReady: (selectedDates, dateStr, instance) => {
      instance.element.value = dateStr.replace('to', '-');
      const customClass = (align) ? align : '';
      instance.calendarContainer.classList.add(`flatpickr-${customClass}`);
    },
    onChange: (selectedDates, dateStr, instance) => {
      instance.element.value = dateStr.replace('to', '-');
      var dateOne = instance.element.value.split(' - ')[0]
      var dateTwo = instance.element.value.split(' - ')[1] ? instance.element.value.split(' - ')[1] : false;
      if(dateOne && dateTwo) {
        console.log('here we are when tie changed')
        const newStartTime = new Date(dateOne).toLocaleDateString('en-CA')
        const newEndTime = new Date(dateTwo).toLocaleDateString('en-CA')
        const timeDatesPicked = new Date();
        const storeInfo = {
          newStartTime,
          newEndTime,
          timeDatesPicked
        }
        localStorage.setItem("dateTimePicker", JSON.stringify(storeInfo) )
        dispatch(setDateRange({startTime : newStartTime, endTime : newEndTime}))
      }
    },
  }

  return (
    <div className="relative">
      <Flatpickr className="form-input pl-9 text-gray-500 hover:text-gray-600 font-medium focus:border-gray-300 w-60" options={options} />
      <div className="absolute inset-0 right-auto flex items-center pointer-events-none">
        <svg className="w-4 h-4 fill-current text-gray-500 ml-3" viewBox="0 0 16 16">
          <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
        </svg>
      </div>
    </div>
  );
}

const dateState = (state)=>({
  startTime: state.date.startTime,
  endTime: state.date.endTime,
})

export default connect(dateState)(Datepicker)
