import React from 'react';
import LineChart from '../../charts/LineChart03';

// Import utilities
import { formatMS } from '../../utils/Format';
import { tailwindConfig, hexToRGB } from '../../utils/Utils';

function AnalyticsCard01({ userAnalytics }) {

  return (
    <div className="flex flex-col col-span-6 xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100 flex items-center">
        <h2 className="font-semibold text-gray-800">Behaviour</h2>
      </header>
      <div className="px-5 py-1">
        <div className="flex flex-wrap">
          {/* Unique Visitors */}
          <div className="flex items-center py-2">
            <div className="mr-5">
              <div className="flex items-center">
                <div className="w-9 h-9 rounded-full flex-shrink-0 bg-red-500 my-2 mr-3">
                  <svg xmlns="http://www.w3.org/2000/svg" style={{marginLeft:4,marginTop:4}} className="w-7 h-7 text-red-50" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFF" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <line x1="3" y1="12" x2="6" y2="12" />
                    <line x1="12" y1="3" x2="12" y2="6" />
                    <line x1="7.8" y1="7.8" x2="5.6" y2="5.6" />
                    <line x1="16.2" y1="7.8" x2="18.4" y2="5.6" />
                    <line x1="7.8" y1="16.2" x2="5.6" y2="18.4" />
                    <path d="M12 12l9 3l-4 2l-2 4l-3 -9" />
                  </svg>
                </div>
                <div className="text-3xl font-bold text-gray-800 mr-2">{userAnalytics.stats.clickStats.count}</div>
                <div className="text-sm text-gray-500">Clicks</div>
              </div>
            </div>
            <div className="hidden md:block w-px h-8 bg-gray-200 mr-5" aria-hidden="true"></div>
            <div className="mr-5">
              <div className="flex items-center">
                <div className="text-1xl font-bold text-gray-800 mr-2">{userAnalytics.stats.popupStats.opened}</div>
                <div className="text-sm text-gray-500">Popups Opened</div>
              </div>
            </div>
          </div>
        </div>
        <div className="mr-5">
          <div className="flex items-center py-2">
            <div className="mr-5">
              <div className="flex items-center">
                <div className="w-9 h-9 rounded-full flex-shrink-0 bg-green-500 my-2 mr-3">
                  <svg xmlns="http://www.w3.org/2000/svg" style={{marginLeft:4,marginTop:4}} className="w-7 h-7 text-green-50" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFF" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M13 21v-13a4 4 0 1 1 4 4h-13" />
                    <path d="M7 15l-3 -3l3 -3" />
                  </svg>
                </div>
                <div className="text-3xl font-bold text-gray-800 mr-2">{userAnalytics.stats.moveStats.count}</div>
                <div className="text-sm text-gray-500">Moves</div>
              </div>
            </div>
            <div className="hidden md:block w-px h-8 bg-gray-200 mr-5" aria-hidden="true"></div>
            <div className="mr-5">
              <div className="flex items-center">
                <div className="text-1xl font-bold text-gray-800 mr-2">{userAnalytics.stats.moveStats.pans}</div>
                <div className="text-sm text-gray-500">Pans</div>
              </div>
            </div>
            <div className="mr-5">
              <div className="flex items-center">
                <div className="text-1xl font-bold text-gray-800 mr-2">{userAnalytics.stats.moveStats.zooms}</div>
                <div className="text-sm text-gray-500">Zooms</div>
              </div>
            </div>
            <div className="mr-5">
              <div className="flex items-center">
                <div className="text-1xl font-bold text-gray-800 mr-2">{userAnalytics.stats.moveStats.rotations}</div>
                <div className="text-sm text-gray-500">Rotations</div>
              </div>
            </div>
            <div className="mr-5">
              <div className="flex items-center">
                <div className="text-1xl font-bold text-gray-800 mr-2">{userAnalytics.stats.moveStats.tilts}</div>
                <div className="text-sm text-gray-500">Tilts</div>
              </div>
            </div>
          </div>
          {/* Visit Duration*/}
          <div className="flex items-center py-2">
            <div className="mr-5">
              <div className="flex items-center">
                <div className="w-9 h-9 rounded-full flex-shrink-0 bg-light-blue-500 my-2 mr-3">
                  <svg className="w-9 h-9 fill-current text-green-50" viewBox="0 0 36 36">
                    <path d="M15 13v-3l-5 4 5 4v-3h8a1 1 0 000-2h-8zM21 21h-8a1 1 0 000 2h8v3l5-4-5-4v3z" />
                  </svg>
                </div>
                <div className="text-3xl font-bold text-gray-800 mr-2">{userAnalytics.stats.mousemoveStats.count}</div>
                <div className="text-sm text-gray-500">Mousemoves</div>
              </div>
            </div>
            <div className="hidden md:block w-px h-8 bg-gray-200 mr-5" aria-hidden="true"></div>
            <div className="mr-5">
              <div className="flex items-center">
                <div className="text-1xl font-bold text-gray-800 mr-2">{formatMS(userAnalytics.stats.mousemoveStats.mousetime)}</div>
                <div className="text-sm text-gray-500">Mousemove Time</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function formatUserTime(totalMilliseconds) {
  var second = 1000;
  var minute = second * 60;
  var hour = minute * 60;
  if(totalMilliseconds > hour) {
    var totalHours = Math.floor(totalMilliseconds / hour);
    var extraMinutes = Math.floor((totalMilliseconds - (totalHours * hour)) / minute);
    return totalHours + 'h ' + extraMinutes + 'm'
  }
  if(totalMilliseconds > minute) {
    var totalMinutes = Math.floor(totalMilliseconds / minute);
    var extraSeconds = Math.floor((totalMilliseconds - (totalMinutes * minute)) / second);
    return totalMinutes + 'm ' + extraSeconds + 's'
  }
  if(totalMilliseconds > second) {
    var totalSeconds = Math.floor(totalMilliseconds / second);
    var extraMilliseconds = Math.floor(totalMilliseconds - (totalSeconds * second));
    return totalSeconds + 's ' + extraMilliseconds + 'ms'
  }
}

export default AnalyticsCard01;
