import React, { useState, useEffect } from 'react';
import maplibregl from 'maplibre-gl';
import * as turf from '@turf/turf'

function AnalyticsMap({ getMapData, analytics, clickData, boundsData, selectedMap }) {

  const [ map, setMap ] = useState(false)
  const [ loading, setLoading] = useState(true);
  const [ currentBounds, setCurrentBounds ] = useState(false);
  const [ showBoundsRects, setShowBoundsRects ] = useState(false);
  const [ showClickDots, setShowClickDots ] = useState(false);
  const [ type, setType ] = useState('bounds');
  const [ maxNumberMap, setMaxNumberMap ] = useState(false);
  const [ minNumberMap, setMinNumberMap ] = useState(false);

  useEffect(() => {
    const newMap = new maplibregl.Map({
      container: 'map', // container ID
      style: {
        'version': 8,
        "glyphs": "https://fonts.openmaptiles.org/{fontstack}/{range}.pbf",
        'sources': {
          'raster-tiles': {
          'type': 'raster',
          'tiles': [
              'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'
            ],
            'tileSize': 256,
            'attribution': '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          }
        },
        'layers': [
          {
          'id': 'simple-tiles',
          'type': 'raster',
          'source': 'raster-tiles',
          'minzoom': 0,
          'maxzoom': 22
          }
        ]
      }, // style URL
      center: [-100.8603, 38.2700], // starting position [lng, lat]
      zoom: 3 // starting zoom
    });
    newMap.on('load', () => {
      setMap(newMap);
      var mapboxBounds = newMap.getBounds();
      var bounds = [mapboxBounds._ne.lng, mapboxBounds._ne.lat, mapboxBounds._sw.lng, mapboxBounds._sw.lat]
      setCurrentBounds(bounds);
    })
    newMap.on('moveend', () => {
      var mapboxBounds = newMap.getBounds();
      var bounds = [mapboxBounds._ne.lng, mapboxBounds._ne.lat, mapboxBounds._sw.lng, mapboxBounds._sw.lat]
      setCurrentBounds(bounds);
    })
  }, [])

  useEffect(() => {
    if(map && currentBounds) {
      getMapData(currentBounds, type)
      setLoading(true);
    }
  }, [currentBounds])

  useEffect(() => {
    if(map) {
      if(type === 'clicks' && clickData) {
        createClickHeatmap(map, clickData)
      }
      if(type === 'bounds' && boundsData) {
        createBoundsHeatmap(map, boundsData)
      }
      setLoading(false)
    }
  }, [type, clickData, boundsData])

  const createBoundsHeatmap = (map, boundsData) => {
    if(map.getLayer('click-hex-fill')) {
      map.setLayoutProperty('click-hex-fill', 'visibility', 'none');
    }
    if(map.getLayer('bounds-hex-fill')) {
      map.setLayoutProperty('bounds-hex-fill', 'visibility', 'visible');
    }
    setMinNumberMap(boundsData.minIntersects)
    setMaxNumberMap(boundsData.maxIntersects)
    if(boundsData.minIntersects !== 999 && boundsData.maxIntersects !== 0) {
      if(map.getSource('bounds-hex')) {
        map.getSource('bounds-hex').setData(boundsData.hexGrid)
        var intersectDifference = boundsData.maxIntersects - boundsData.minIntersects;
        map.setPaintProperty('bounds-hex-fill', 'fill-color', [
          'interpolate',
          ['linear'],
          ['get', 'count'],
          boundsData.minIntersects,
          'blue',
          (boundsData.minIntersects + (intersectDifference*0.2)),
          'royalblue',
          (boundsData.minIntersects + (intersectDifference*0.4)),
          'cyan',
          (boundsData.minIntersects + (intersectDifference*0.6)),
          'lime',
          (boundsData.minIntersects + (intersectDifference*0.8)),
          'yellow',
          boundsData.maxIntersects,
          'red'
        ])
      } else {
        console.log('adding hex')
        map.addSource('bounds-hex', {
          type : 'geojson',
          data : boundsData.hexGrid
        })
        var intersectDifference = boundsData.maxIntersects - boundsData.minIntersects;
        map.addLayer({
          id : 'bounds-hex-fill',
          source : 'bounds-hex',
          type: 'fill',
          paint : {
            'fill-outline-color' : 'rgba(0,0,0,0)',
            'fill-color' : [
              'interpolate',
              ['linear'],
              ['get', 'count'],
              boundsData.minIntersects,
              'blue',
              (boundsData.minIntersects + (intersectDifference*0.2)),
              'royalblue',
              (boundsData.minIntersects + (intersectDifference*0.4)),
              'cyan',
              (boundsData.minIntersects + (intersectDifference*0.6)),
              'lime',
              (boundsData.minIntersects + (intersectDifference*0.8)),
              'yellow',
              boundsData.maxIntersects,
              'red'
            ],
            'fill-opacity' : 0.2
          }
        })
      }
    }
  }

  const createClickHeatmap = (map, clickData) => {
    if(map.getLayer('click-hex-fill')) {
      map.setLayoutProperty('click-hex-fill', 'visibility', 'visible');
    }
    if(map.getLayer('bounds-hex-fill')) {
      map.setLayoutProperty('bounds-hex-fill', 'visibility', 'none');
    }
    if(map.getSource('click-hex')) {
      map.getSource('click-hex').setData(clickData.hexGrid)
      var intersectDifference = clickData.maxIntersects - clickData.minIntersects;
      map.setPaintProperty('click-hex-fill', 'fill-color', [
        'interpolate',
        ['linear'],
        ['get', 'count'],
        clickData.minIntersects,
        'blue',
        (clickData.minIntersects + (intersectDifference*0.2)),
        'royalblue',
        (clickData.minIntersects + (intersectDifference*0.4)),
        'cyan',
        (clickData.minIntersects + (intersectDifference*0.6)),
        'lime',
        (clickData.minIntersects + (intersectDifference*0.8)),
        'yellow',
        clickData.maxIntersects,
        'red'
      ])
    } else {
      map.addSource('click-hex', {
        type : 'geojson',
        data : clickData.hexGrid
      })
      map.addLayer({
        id : 'click-hex-fill',
        source : 'click-hex',
        type: 'fill',
        paint : {
          'fill-outline-color' : 'rgba(0,0,0,0)',
          'fill-color' : [
            'interpolate',
            ['linear'],
            ['get', 'count'],
            clickData.minIntersects,
            'blue',
            (clickData.minIntersects + (intersectDifference*0.2)),
            'royalblue',
            (clickData.minIntersects + (intersectDifference*0.4)),
            'cyan',
            (clickData.minIntersects + (intersectDifference*0.6)),
            'lime',
            (clickData.minIntersects + (intersectDifference*0.8)),
            'yellow',
            clickData.maxIntersects,
            'red'
          ],
          'fill-opacity' : 0.2
        }
      })
    }
  }

  useEffect(() => {
    if(map && showBoundsRects) {
      if(map.getSource('bounds-events')) {
        map.setLayoutProperty('bounds-events', 'visibility', 'visible');
        map.getSource('bounds-events').setData(boundsData.boundsGeoJSON)
      } else {
        map.addSource('bounds-events', {
          type : 'geojson',
          data : boundsData.boundsGeoJSON
        })
        map.addLayer({
          id : 'bounds-events',
          source : 'bounds-events',
          type: 'line',
          paint : {
            'line-opacity' : 0.3
          }
        })
      }
    } else if(map && !showBoundsRects) {
      if(map.getLayer('bounds-events')) {
        map.setLayoutProperty('bounds-events', 'visibility', 'none');
      }
    }
  }, [showBoundsRects])

  useEffect(() => {
    if(map && showClickDots) {
      if(map.getSource('click-events')) {
        map.setLayoutProperty('click-events', 'visibility', 'visible');
        map.getSource('click-events').setData(clickData.clickGeoJSON)
      } else {
        map.addSource('click-events', {
          type : 'geojson',
          data : clickData.clickGeoJSON
        })
        map.addLayer({
          id : 'click-events',
          source : 'click-events',
          type: 'circle'
        })
      }
    } else if(map && !showClickDots) {
      if(map.getLayer('click-events')) {
        map.setLayoutProperty('click-events', 'visibility', 'none');
      }
    }
  }, [showClickDots])

  useEffect(() => {
    if(map) {
      var mapboxBounds = map.getBounds();
      var bounds = [mapboxBounds._ne.lng, mapboxBounds._ne.lat, mapboxBounds._sw.lng, mapboxBounds._sw.lat]
      getMapData(bounds, type)
      setLoading(true);
    }
  }, [type])

  // A few buttons: for click heatmap, bounds/view heatmap, load heatmap
  // Need to basically define viewed area, get bounds and split into a grid, then color the grid and also place the specific dots

  return (
    <div className="flex flex-col col-span-full xl:col-span-12 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100 flex items-center">
        <h2 className="font-semibold text-gray-800">
          Heatmap
        </h2>
        {loading ?
          <svg style={{marginLeft:'10px', display:'inline'}} width="16" height="16" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#333">
              <g fill="none" fill-rule="evenodd">
                  <g transform="translate(1 1)" stroke-width="2">
                      <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
                      <path d="M36 18c0-9.94-8.06-18-18-18">
                          <animateTransform
                              attributeName="transform"
                              type="rotate"
                              from="0 18 18"
                              to="360 18 18"
                              dur="1s"
                              repeatCount="indefinite"/>
                      </path>
                  </g>
              </g>
          </svg>
        : false}
        <div style={{textAlign: 'right', width: '100%'}} className="legend-swatches">
          <div style={{display:'inline-block', width: 12, height: 12, backgroundColor: 'blue'}} /> {minNumberMap}
          <div style={{display:'inline-block', width: 12, height: 12, backgroundColor: 'royalblue', marginLeft: '20px'}} /> {(maxNumberMap * 0.2).toFixed(1)}
          <div style={{display:'inline-block', width: 12, height: 12, backgroundColor: 'cyan', marginLeft: '20px'}} /> {(maxNumberMap * 0.4).toFixed(1)}
          <div style={{display:'inline-block', width: 12, height: 12, backgroundColor: 'lime', marginLeft: '20px'}} /> {(maxNumberMap * 0.6).toFixed(1)}
          <div style={{display:'inline-block', width: 12, height: 12, backgroundColor: 'yellow', marginLeft: '20px'}} /> {(maxNumberMap * 0.8).toFixed(1)}
          <div style={{display:'inline-block', width: 12, height: 12, backgroundColor: 'red', marginLeft: '20px'}} /> {maxNumberMap}
        </div>
      </header>
      <div>
        <div id="map" style={{width: '100%', height: '400px'}}/>
      </div>
      <div style={{margin: 10, display: 'flex' }}>
        <div style={{margin: 10, textAlign: 'left', width: '50%'}}>
          {type === 'clicks' ?
            <div>
              <input type="checkbox" onChange={() => setShowClickDots(!showClickDots)} checked={showClickDots} /> Show click dots
            </div>
          : false}
          {type === 'bounds' ?
            <div>
              <input type="checkbox" onChange={() => setShowBoundsRects(!showBoundsRects)} checked={showBoundsRects} /> Show rectangular bounds (max 200 at a time)
            </div>
          : false}
        </div>
        <div style={{textAlign: 'right', width: '50%'}}>
          <button onClick={() => setType('clicks')} style={{marginRight: 20}} className={`btn bg-gray-${type === 'clicks' ? '700' : '400'} hover:bg-gray-600 text-white`}>
            <span className="hidden xs:block">Clicks</span>
          </button>
          <button onClick={() => setType('bounds')} className={`btn bg-gray-${type === 'bounds' ? '700' : '400'} hover:bg-gray-600 text-white`}>
            <span className="hidden xs:block">Viewed Areas</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default AnalyticsMap;
