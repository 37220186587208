const API_URL = process.env.REACT_APP_URL;
export const SET_MAPS = 'SET_MAPS';
export const ADD_MAP = 'ADD_MAP';
export const REMOVE_MAP = 'REMOVE_MAP';

export function getMaps(account_id) {
    return (dispatch) => {
        console.log(`${API_URL}/api/maps/list/${account_id}`)
        return fetch(`${API_URL}/api/maps/list/${account_id}`)
        .then(resp => resp.json()).then(response => {
            dispatch(setMaps(response.maps))
        })
        .catch((error) => console.log("MAP LIST ERROR: ", error));
    };
}

export function createMap(map_name, domain, account_id) {
    return (dispatch) => {
        return fetch(`${API_URL}/api/maps/create`, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify({map_name, domain, account_id})
        })
        .then(resp => resp.json()).then(response => {
            dispatch(addMap(response.map))
        })
        .catch((error) => console.log("MAP CREATION ERROR: ", error));
    };
}

export function deleteMap(map_id, account_id) {
    return (dispatch) => {
        return fetch(`${API_URL}/api/maps/delete`, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify({map_id, account_id})
        })
        .then(resp => resp.json()).then(response => {
            dispatch(removeMap(response.map_id))
        })
        .catch((error) => console.log("MAP DELETION ERROR: ", error));
    };
}

export function setMaps(payload){
    return {
        type: SET_MAPS,
        payload: payload
    };
}

export function addMap(payload){
    return {
        type: ADD_MAP,
        payload: payload
    };
}

export function removeMap(payload){
    return {
        type: REMOVE_MAP,
        payload: payload
    };
}
