import React from 'react';
import { Link } from 'react-router-dom';
import SummaryChart from '../../charts/SummaryChart01';
import Icon from '../../images/icon-01.svg';
import EditMenu from '../../components/DropdownEditMenu';

// Import utilities
import { tailwindConfig, hexToRGB } from '../../utils/Utils';

function Dashboard24HourSummary({ stats24HourPerHour, title, property }) {

  const chartData = {
    labels: stats24HourPerHour.map(hour => hour.startTime),
    datasets: [
      // Indigo line
      {
        data: stats24HourPerHour.map(hour => hour[property] ? hour[property] : 0),
        fill: true,
        backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.08)`,
        borderColor: tailwindConfig().theme.colors.indigo[500],
        borderWidth: 2,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
        clip: 20,
      },
      // Gray line
      // {
      //   data: [
      //     532, 532, 532, 404, 404, 314, 314,
      //     314, 314, 314, 234, 314, 234, 234,
      //     314, 314, 314, 388, 314, 202, 202,
      //     202, 202, 314, 720, 642,
      //   ],
      //   borderColor: tailwindConfig().theme.colors.gray[300],
      //   borderWidth: 2,
      //   tension: 0,
      //   pointRadius: 0,
      //   pointHoverRadius: 3,
      //   pointBackgroundColor: tailwindConfig().theme.colors.gray[300],
      //   clip: 20,
      // },
    ],
  };

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200">
      <div className="px-5 pt-5">
        <header className="flex justify-between items-start mb-2" style={{marginBottom: 0}}>
          <h2 className="text-lg font-semibold text-gray-800 mb-2">{title}</h2>
          {/* Menu button */}
          <div className="text-3xl font-bold text-gray-800 mr-2">{stats24HourPerHour.map(o => o[property]).reduce((a, b) => a + b, 0)}</div>

          {/* <EditMenu align="right" className="relative inline-flex">
            <li>
              <Link className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3" to="#0">See Analytics</Link>
            </li>
          </EditMenu> */}
        </header>
        {/* <div className="text-xs font-semibold text-gray-400 uppercase mb-1">24-hour Comparison</div> */}
        {/* <div className="flex items-start">
          <div className="text-3xl font-bold text-gray-800 mr-2">{stats24HourPerHour.map(o => o[property]).reduce((a, b) => a + b, 0)}</div>
          <div className="text-sm font-semibold text-white px-1.5 bg-green-500 rounded-full">+49%</div>
        </div> */}
      </div>
      {/* Chart built with Chart.js 3 */}
      <div className="flex-grow">
        {/* Change the height attribute to adjust the chart height */}
        <SummaryChart data={chartData} width={389} height={128} />
      </div>
    </div>
  );
}

export default Dashboard24HourSummary;
