import React from 'react';

function DashboardCard07({ summary }) {

  var platforms = [];
  summary.forEach(summaryData => {
    for(var platform in summary) {

    }
  })
  return (
    <div className="col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">Top Platforms</h2>
      </header>
      <div className="p-3">

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
              <tr>
                <th className="p-2">
                  <div className="font-semibold text-left">Platform</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-center">Sessions</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm font-medium divide-y divide-gray-100">
              {/* Row */}
              <tr>
                <td className="p-2">
                  <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 mr-2 sm:mr-3" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                      <rect x="3" y="4" width="18" height="12" rx="1" />
                      <line x1="7" y1="20" x2="17" y2="20" />
                      <line x1="9" y1="16" x2="9" y2="20" />
                      <line x1="15" y1="16" x2="15" y2="20" />
                    </svg>
                    <div className="text-gray-800">Desktop</div>
                  </div>
                </td>
                <td className="p-2">
                  <div className="text-center">{summary.reduce((a, b) => b.platforms.desktop + a, 0)}</div>
                </td>
              </tr>
              <tr>
                <td className="p-2">
                  <div className="flex items-center">
                    <div className="text-gray-800">Mobile</div>
                  </div>
                </td>
                <td className="p-2">
                  <div className="text-center">{summary.reduce((a, b) => b.platforms.mobile + a, 0)}</div>
                </td>
              </tr>
              <tr>
                <td className="p-2">
                  <div className="flex items-center">
                    <div className="text-gray-800">Tablet</div>
                  </div>
                </td>
                <td className="p-2">
                  <div className="text-center">{summary.reduce((a, b) => b.platforms.tablet + a, 0)}</div>
                </td>
              </tr>
              <tr>
                <td className="p-2">
                  <div className="flex items-center">
                    <div className="text-gray-800">Unknown</div>
                  </div>
                </td>
                <td className="p-2">
                  <div className="text-center">{summary.reduce((a, b) => b.platforms.unknown + a, 0)}</div>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  );
}

export default DashboardCard07;
