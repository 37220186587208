import React from 'react';
import DoughnutChart from '../../charts/DoughnutChart';

// Import utilities
import { tailwindConfig } from '../../utils/Utils';

function DashboardCard06({ summary }) {

  if(summary.length === 0) {
    return false;
  }

  var countries = []
  summary.forEach(summaryData => {
    if(countries.length === 0) {
      countries = summaryData.sessionLocations
    } else {
      summaryData.sessionLocations.forEach(location => {
        var thisIndex = countries.findIndex(country => country.name === location.country)
        if(location && thisIndex === -1) {
          countries.push(location);
        } else if(location && thisIndex > -1) {
          countries[thisIndex].count = countries[thisIndex].count + location.count;
        }
      })
    }
  })
  var top5only = countries.slice(0, 5);

  const chartData = {
    labels: top5only.map(country => country.name),
    datasets: [
      {
        label: 'Top Countries',
        data: top5only.map(country => country.count),
        backgroundColor: [
          tailwindConfig().theme.colors.indigo[500],
          tailwindConfig().theme.colors.blue[400],
          tailwindConfig().theme.colors.indigo[800],
        ],
        hoverBackgroundColor: [
          tailwindConfig().theme.colors.indigo[600],
          tailwindConfig().theme.colors.blue[500],
          tailwindConfig().theme.colors.indigo[900],
        ],
        hoverBorderColor: tailwindConfig().theme.colors.white,
      },
    ],
  };

  return (
    <div className="flex flex-col col-span-full sm:col-span-5 xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">Sessions by Country</h2>
      </header>
      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}
      <DoughnutChart data={chartData} width={389} height={260} />
    </div>
  );
}

export default DashboardCard06;
