import React from 'react';
import DoughnutChart from '../../charts/DoughnutChart';

// Import utilities
import { tailwindConfig } from '../../utils/Utils';

function AnalyticsCard08({ analytics }) {

  var platformLabels = [];
  var platformStats = [];
  for(var platform in analytics.loadStats.platforms) {
    var capitalized = platform.replace(/(^|\s)\S/g, letter => letter.toUpperCase())
    if(platformLabels.indexOf(capitalized) === -1) {
      platformLabels.push(capitalized);
      platformStats.push(analytics.loadStats.platforms[platform])
    }
  }

  const chartData = {
    labels: platformLabels,
    datasets: [
      {
        label: 'Sessions By Platform',
        data: platformStats,
        backgroundColor: [
          tailwindConfig().theme.colors.indigo[500],
          tailwindConfig().theme.colors['light-blue'][400],
          tailwindConfig().theme.colors.indigo[800],
        ],
        hoverBackgroundColor: [
          tailwindConfig().theme.colors.indigo[600],
          tailwindConfig().theme.colors['light-blue'][500],
          tailwindConfig().theme.colors.indigo[900],
        ],
        hoverBorderColor: tailwindConfig().theme.colors.white,
      },
    ],
  };

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">Sessions By Platform</h2>
      </header>
        {/* Chart built with Chart.js 3 */}
        {/* Change the height attribute to adjust the chart height */}
      <DoughnutChart data={chartData} width={389} height={260} />
    </div>
  );
}

export default AnalyticsCard08;
