import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  useLocation,
  useHistory,
  Redirect
} from 'react-router-dom';
import { useDispatch } from 'react-redux';

import './css/style.scss';

import { focusHandling } from 'cruip-js-toolkit';
import './charts/ChartjsConfig';

import { loginFromToken } from './actions/actions_account';

// Import pages
import Dashboard from './pages/Dashboard';
import Analytics from './pages/Analytics';
import SessionAnalytics from './pages/SessionAnalytics';
import UserAnalytics from './pages/UserAnalytics';
// import Customers from './pages/ecommerce/Customers';
// import Orders from './pages/ecommerce/Orders';
// import Invoices from './pages/ecommerce/Invoices';
// import Shop from './pages/ecommerce/Shop';
// import Shop2 from './pages/ecommerce/Shop2';
// import Product from './pages/ecommerce/Product';
// import Campaigns from './pages/Campaigns';
// import TeamTabs from './pages/team/TeamTabs';
// import TeamTiles from './pages/team/TeamTiles';
// import Messages from './pages/Messages';
// import Tasks from './pages/Tasks';
import Account from './pages/settings/Account';
// import Notifications from './pages/settings/Notifications';
import Apps from './pages/settings/Apps';
import Plans from './pages/settings/Plans';
import Billing from './pages/settings/Billing';
// import Feedback from './pages/settings/Feedback';
// import Changelog from './pages/utility/Changelog';
// import Roadmap from './pages/utility/Roadmap';
import Faqs from './pages/utility/Faqs';
// import EmptyState from './pages/utility/EmptyState';
import PageNotFound from './pages/utility/PageNotFound';
import Signin from './pages/Signin';
import Signup from './pages/Signup';
import ResetPassword from './pages/ResetPassword';

import MapsList from './pages/maps/MapsList';
import MapsAdd from './pages/maps/MapsAdd';
import Users from './pages/Users';
import Sessions from './pages/Sessions';

function App() {

  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory()

  useEffect(() => {
    const localStorageEmail = localStorage.getItem('mapster-dashboard-email')
    const localStorageToken = localStorage.getItem('mapster-dashboard-token')
    if(localStorageEmail && localStorageToken){
      dispatch(loginFromToken(localStorageEmail, localStorageToken))
    } else {
      history.push({ pathname : '/signin', search : window.location.search })
    }
  }, [])

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Switch>
        <Route exact path="/">
          {
            localStorage.getItem('mapster-dashboard-email') && localStorage.getItem('mapster-dashboard-token')?
            <Redirect to='/dashboard'/>:  <Redirect to='/signin'/>
          }
          <Signin />
        </Route>
        <Route exact path="/signin">
          <Signin />
        </Route>
        <Route exact path="/confirm_email">
          <Signin />
        </Route>
        <Route exact path="/signup">
          <Signup />
        </Route>
        <Route exact path="/reset-password">
          <ResetPassword />
        </Route>
        <Route exact path="/dashboard">
          <Dashboard />
        </Route>
        <Route exact path="/analytics">
          <Analytics />
        </Route>
        {/*
        <Route exact path="/ecommerce/customers">
          <Customers />
        </Route>
        <Route exact path="/ecommerce/orders">
          <Orders />
        </Route>
        <Route exact path="/ecommerce/invoices">
          <Invoices />
        </Route>
        <Route exact path="/ecommerce/shop">
          <Shop />
        </Route>
        <Route exact path="/ecommerce/shop-2">
          <Shop2 />
        </Route>
        <Route exact path="/ecommerce/product">
          <Product />
        </Route>
        <Route exact path="/campaigns">
          <Campaigns />
        </Route>
        <Route exact path="/team/team-tabs">
          <TeamTabs />
        </Route>
        <Route exact path="/team/team-tiles">
          <TeamTiles />
        </Route>
        <Route exact path="/messages">
          <Messages />
        </Route>
        <Route exact path="/tasks">
          <Tasks />
        </Route>
        */}
        <Route exact path="/maps/list">
          <MapsList />
        </Route>
        <Route exact path="/maps/add">
          <MapsAdd />
        </Route>
        <Route exact path="/users">
          <Users />
        </Route>
        <Route exact path="/users/single">
          <UserAnalytics />
        </Route>
        <Route exact path="/sessions">
          <Sessions />
        </Route>
        <Route exact path="/sessions/single">
          <SessionAnalytics />
        </Route>
        <Route exact path="/settings/account">
          <Account />
        </Route>
        {/*
        <Route exact path="/settings/notifications">
          <Notifications />
        </Route>
        <Route exact path="/settings/apps">
          <Apps />
        </Route>
        */}
        <Route exact path="/settings/plans">
          <Plans />
        </Route>
        <Route exact path="/settings/billing">
          <Billing />
        </Route>
        {/*
        <Route exact path="/settings/feedback">
          <Feedback />
        </Route>
        <Route exact path="/utility/changelog">
          <Changelog />
        </Route>
        <Route exact path="/utility/roadmap">
          <Roadmap />
        </Route>
        */}
        <Route exact path="/help/faqs">
          <Faqs />
        </Route>
        {/*
        <Route exact path="/utility/empty-state">
          <EmptyState />
        </Route>
        */}
        <Route exact path="/utility/404">
          <PageNotFound />
        </Route>
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </>
  );
}

export default App;
