export const formatMS = (ms) => {
  if(ms <= 1000) {
    return ms + 'ms';
  }
  if(ms <= (1000 * 60)) {
    return (ms/1000).toFixed(2) + 's';
  }
  if(ms <= (1000 * 60 * 60)) {
    return (ms/(1000 * 60)).toFixed(2) + 'min';
  }
  return (ms/(1000 * 60 * 60)).toFixed(2) + 'hr';
}
