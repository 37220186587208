const { SET_LOGIN, SET_MESSAGE, SET_LOGOUT, CLEAR_MSG } = require("../actions/actions_account");

const initialState = {
    token: null,
    message : null,
    currentAccount : null
}

const reducer_account = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_MESSAGE:
            var newState = JSON.parse(JSON.stringify(state))
            newState.message = payload.msg
            return newState
        case SET_LOGIN:
            var newState = JSON.parse(JSON.stringify(state))
            newState.token = payload.token
            newState.currentAccount = payload.user
            localStorage.setItem('mapster-dashboard-email', payload.user.email)
            localStorage.setItem('mapster-dashboard-token', payload.token)
            return newState
        case SET_LOGOUT:
            var newState = JSON.parse(JSON.stringify(state))
            localStorage.removeItem('mapster-dashboard-email')
            localStorage.removeItem('mapster-dashboard-token')
            newState.token = null
            newState.currentAccount = null
            return newState
        case CLEAR_MSG:
            var newState = JSON.parse(JSON.stringify(state))

            newState.message = null;
        return newState

        default:
            return state
    }
}
export default reducer_account;
