import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import Datepicker from '../components/Datepicker';
import SearchForm from '../partials/actions/SearchForm';
import DeleteButton from '../partials/actions/DeleteButton';
import DateSelect from '../components/DateSelect';
import FilterButton from '../components/DropdownFilter';
import UsersTable from '../partials/users/UsersTable';
import PaginationClassic from '../components/PaginationClassic';

import { getUsers } from '../actions/actions_users';

function Users({ dispatch, allUsers, startTime, endTime, currentAccount, totalUsers }) {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if(currentAccount) {
      dispatch(getUsers(currentAccount.id, startTime, endTime, currentPage))
    }
  }, [currentAccount])

  useEffect(() => {
    if(currentAccount) {
      dispatch(getUsers(currentAccount.id, startTime, endTime, currentPage))
    }
  }, [currentPage, currentAccount, startTime, endTime])

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">

              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">Map Users</h1>
                <p>Click on user IDs to view more about that user. IDs are generated randomly.</p>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">

                {/* Datepicker built with flatpickr */}
                <Datepicker align="right" />

              </div>

            </div>

            {/* Table */}
            <UsersTable allUsers={allUsers} />

            {/* Pagination */}
            <div className="mt-8">
              <PaginationClassic total={totalUsers} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            </div>

          </div>
        </main>

      </div>

    </div>
  );
}

const userState = (state)=>({
  allUsers: state.users.allUsers,
  totalUsers: state.users.totalUsers,
  startTime: state.date.startTime,
  endTime: state.date.endTime,
  currentAccount : state.account.currentAccount
})

export default connect(userState)(Users)
