import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import Select from '../partials/actions/Select';
import Datepicker from '../components/Datepicker';
import AnalyticsMap from '../partials/userAnalytics/AnalyticsMap';
import AnalyticsCard01 from '../partials/userAnalytics/AnalyticsCard01';
import AnalyticsCard03 from '../partials/userAnalytics/AnalyticsCard03';
import AnalyticsCard04 from '../partials/userAnalytics/AnalyticsCard04';
import AnalyticsCard05 from '../partials/userAnalytics/AnalyticsCard05';
import AnalyticsCard06 from '../partials/userAnalytics/AnalyticsCard06';
import AnalyticsCard11 from '../partials/userAnalytics/AnalyticsCard11';
import AnalyticsCard12 from '../partials/userAnalytics/AnalyticsCard12';

import { getUserAnalytics, setUserAnalytics } from '../actions/actions_users'

function UserAnalytics({ dispatch, userAnalytics, startTime, endTime }) {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date().getTime())
  const [currentUser, setCurrentUser] = useState(false);

  useEffect(() => {
    dispatch(setUserAnalytics(false))
    let searchParams = new URLSearchParams(window.location.search)
    if(searchParams.has('user_id')) {
      dispatch(getUserAnalytics(searchParams.get('user_id'), startTime, endTime))
      setCurrentUser(searchParams.get('user_id'))
    }
  }, [])

  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search)
    dispatch(getUserAnalytics(searchParams.get('user_id'), startTime, endTime))
  }, [startTime, endTime])

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">

              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">User Analytics</h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">

                {/* Datepicker built with flatpickr */}
                <Datepicker align="right" />

              </div>

            </div>

            {/* Cards */}
            {userAnalytics ?
              <div className="grid grid-cols-12 gap-6">

                  <AnalyticsMap analytics={userAnalytics} />

                  <AnalyticsCard01 userAnalytics={userAnalytics} />
                  <AnalyticsCard06 userAnalytics={userAnalytics} />
                  <AnalyticsCard03 userAnalytics={userAnalytics} />
                  <AnalyticsCard04 userAnalytics={userAnalytics} />
                  <AnalyticsCard05 userAnalytics={userAnalytics} />
                  <AnalyticsCard11 userAnalytics={userAnalytics} />
                  <AnalyticsCard12 userAnalytics={userAnalytics} />

              </div>
            : false}

          </div>
        </main>

      </div>

    </div>
  );
}

const analyticsState = (state)=>({
  userAnalytics : state.users.userAnalytics,
  startTime: state.date.startTime,
  endTime: state.date.endTime,
})

export default connect(analyticsState)(UserAnalytics)
