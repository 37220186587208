import React, { useState, useEffect } from 'react';
import Maps from './MapsTableItem';

import Image from '../../images/user-avatar-80.png';

function AccountPanel({ allMaps, removeMap }) {

  const [sync, setSync] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(allMaps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMaps]);

  return (
    <div className="flex-grow">
      {/* Panel body */}
      <div className="p-6 space-y-6">
        <h2 className="text-2xl text-gray-800 font-bold mb-5">My Maps</h2>
        {/* Business Profile */}
        <section>

          <div className="overflow-x-auto">
            <table className="table-auto w-full">
              {/* Table header */}
              <thead className="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-t border-b border-gray-200">
                <tr>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="font-semibold text-left">Map Name</div>
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="font-semibold text-left">Map Key</div>
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="font-semibold text-left">Root URL</div>
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="font-semibold text-left">Views</div>
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="font-semibold text-left">Created</div>
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="font-semibold text-left">Type</div>
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="font-semibold text-left">Actions</div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              <tbody className="text-sm divide-y divide-gray-200">
                {
                  list.map(map => {
                    return (
                      <Maps
                        key={map.id}
                        id={map.id}
                        map_name={map.map_name}
                        map_id={map.map_id}
                        domain={map.domain}
                        views={map.views}
                        created={map.created}
                        type={map.type}
                        removeMap={removeMap}
                      />
                    )
                  })
                }
              </tbody>
            </table>

          </div>
        </section>
      </div>
    </div>
  );
}

export default AccountPanel;
