import React from 'react';
import { NavLink } from 'react-router-dom';

import Image01 from '../../images/user-28-01.jpg';
import Image02 from '../../images/user-28-02.jpg';
import Image03 from '../../images/user-28-03.jpg';
import Image04 from '../../images/user-28-04.jpg';
import Image05 from '../../images/user-28-05.jpg';
import Image06 from '../../images/user-28-06.jpg';
import Image07 from '../../images/user-28-07.jpg';
import Image09 from '../../images/user-28-09.jpg';
import Image11 from '../../images/user-28-11.jpg';

function AnalyticsCard11({ userAnalytics }) {
  return (
    <div className="col-span-full sm:col-span-7 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">Session List ({userAnalytics.stats.loadStats.sessions.length})</h2>
      </header>
      <div className="p-3">

        {/* Table */}
        <div className="overflow-x-auto" style={{maxHeight: 400, overflowY : 'scroll'}}>
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Session</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Map</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Time</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-100">
              {userAnalytics.stats.loadStats.sessions.map(activity => {
                return (
                  <tr>
                    <td className="p-2 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="font-medium text-light-blue-500"><NavLink to={`/sessions/single?map_id=${activity.map_id}&session_id=${activity.session_id}`}>{activity.session_id}</NavLink></div>
                      </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="font-medium text-light-blue-500"><NavLink to={`/analytics?map_id=${activity.map_id}`}>{activity.map_id}</NavLink></div>
                      </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="font-medium text-gray-800">{new Date(activity.time).toLocaleDateString() + ' ' + new Date(activity.time).toLocaleTimeString()}</div>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>

        </div>
      </div>
    </div>
  );
}

export default AnalyticsCard11;
