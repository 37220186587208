import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

function SettingsSidebar() {

  const location = useLocation();
  const { pathname } = location;

  return (
    <div className="flex flex-nowrap overflow-x-scroll no-scrollbar md:block md:overflow-auto px-3 py-6 border-b md:border-b-0 md:border-r border-gray-200 min-w-60 md:space-y-3">
      {/* Group 1 */}
      <div>
        <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink exact to="/maps/list" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/maps/list') && 'bg-indigo-50'}`}>
              <svg className={`w-4 h-4 flex-shrink-0 fill-current text-gray-400 mr-2 ${pathname.includes('/maps/list') && 'text-indigo-400'}`} viewBox="0 0 16 16">
                <path d="M5 9h11v2H5V9zM0 9h3v2H0V9zm5 4h6v2H5v-2zm-5 0h3v2H0v-2zm5-8h7v2H5V5zM0 5h3v2H0V5zm5-4h11v2H5V1zM0 1h3v2H0V1z" />
              </svg>
              <span className={`text-sm font-medium ${pathname.includes('/maps/list') ? 'text-indigo-500' : 'hover:text-gray-700'}`}>Map List</span>
            </NavLink>
          </li>
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink exact to="/maps/add" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/maps/add') && 'bg-indigo-50'}`}>
              <svg className={`w-4 h-4 flex-shrink-0 fill-current text-gray-400 mr-2 ${pathname.includes('/maps/add') && 'text-indigo-400'}`} viewBox="0 0 16 16">
                <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z"></path>              </svg>
              <span className={`text-sm font-medium ${pathname.includes('/maps/add') ? 'text-indigo-500' : 'hover:text-gray-700'}`}>Add Map</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SettingsSidebar;
