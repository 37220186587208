import React from 'react';

import Image01 from '../../images/user-28-01.jpg';
import Image02 from '../../images/user-28-02.jpg';
import Image03 from '../../images/user-28-03.jpg';
import Image04 from '../../images/user-28-04.jpg';
import Image05 from '../../images/user-28-05.jpg';
import Image06 from '../../images/user-28-06.jpg';
import Image07 from '../../images/user-28-07.jpg';
import Image09 from '../../images/user-28-09.jpg';
import Image11 from '../../images/user-28-11.jpg';

function AnalyticsCard11({ analytics }) {
  return (
    <div className="col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">Top Layer Interactions</h2>
      </header>
      <div className="p-3">

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Layer</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Activity</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-100">
              {analytics.layerStats.topLayers.map(layer => {
                return (
                  <tr>
                    <td className="p-2 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="font-medium text-gray-800">{layer.name}</div>
                      </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="font-medium text-gray-800">{layer.count}</div>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>

        </div>
      </div>
    </div>
  );
}

export default AnalyticsCard11;
