import React from 'react';
import LineChart from '../../charts/LineChart02';

// Import utilities
import { tailwindConfig } from '../../utils/Utils';

function DashboardCard08({ summary }) {

  if(summary.length === 0) {
    return false;
  }

  var labels = summary[0].statsPerPeriod.map(stat => stat.startTime);
  var datasets = [];
  summary.forEach((summaryData, i) => {
    var color = ['indigo', 'blue', 'green']
    datasets.push({
      label: summaryData.map_name,
      data: summaryData.statsPerPeriod.map(stat => stat.sessionNumber),
      borderColor: tailwindConfig().theme.colors[color[i]][500],
      fill: false,
      borderWidth: 2,
      tension: 0,
      pointRadius: 0,
      pointHoverRadius: 3,
      pointBackgroundColor: tailwindConfig().theme.colors[color[i]][500],
    })
  })

  const chartData = {
    labels: labels,
    datasets: datasets
  };

  return (
    <div className="flex flex-col col-span-full sm:col-span-12 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100 flex items-center">
        <h2 className="font-semibold text-gray-800">Views Over Time By Map</h2>
      </header>
      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}
      <LineChart data={chartData} width={595} height={248} />
    </div>
  );
}

export default DashboardCard08;
