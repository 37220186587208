import React, { useRef, useEffect, useState } from 'react';
import _ from 'lodash';

import {
  Chart, LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip,
} from 'chart.js';
import 'chartjs-adapter-moment';

// Import utilities
import { formatThousands } from '../utils/Utils';

Chart.register(LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip);

function LineChart03({
  data,
  width,
  height
}) {

  const canvas = useRef(null);
  const [lastData, setLastData] = useState(false)
  const [currentChart, setCurrentChart] = useState(false);

  useEffect(() => {
    if(!_.isEqual(data, lastData)) {
      setLastData(data);
      if(currentChart) {
        currentChart.destroy();
      }
      const ctx = canvas.current;
      // eslint-disable-next-line no-unused-vars
      const chart = new Chart(ctx, {
        type: 'line',
        data: data,
        options: {
          layout: {
            padding: 20,
          },
          scales: {
            y: {
              beginAtZero: true,
              grid: {
                drawBorder: false,
              },
              ticks: {
                callback: (value) => formatThousands(value),
              },
            },
            x: {
              type: 'time',
              time: {
                parser: 'MM-DD-YYYY',
                unit: 'month',
                displayFormats: {
                  month: 'MMM YY',
                },
              },
              grid: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                autoSkipPadding: 48,
                maxRotation: 0,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                title: () => 'Sessions', // Disable tooltip title
                label: (context) => formatThousands(context.parsed.y),
              },
            },
          },
          interaction: {
            intersect: false,
            mode: 'nearest',
          },
          maintainAspectRatio: false,
          resizeDelay: 200,
        },
      });
      setCurrentChart(chart)
    }
  }, [data]);

  return (
    <canvas ref={canvas} width={width} height={height}></canvas>
  );
}

export default LineChart03;
