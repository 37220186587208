import React, { useState, useEffect, useRef } from 'react';
import maplibregl from 'maplibre-gl';
import * as turf from '@turf/turf'
import Slider, { SliderTooltip, createSliderWithTooltip } from 'rc-slider';

import { formatMS } from '../../utils/Format';

import 'rc-slider/assets/index.css';

const { Handle } = Slider;
const SliderWithTooltip = createSliderWithTooltip(Slider);

var currentTimeouts = [];
var currentInterval = false;

function AnalyticsMap({ sessionAnalytics }) {

  const [ map, setMap ] = useState(false)
  const [ sliderValue, setSliderValue ] = useState(0)
  const [ description, setDescription] = useState(false);
  const currentTimePlaying = useRef(0);
  const [ isPlaying, setIsPlaying ] = useState(false);
  const [ updateTime, setUpdateTime ] = useState(0);

  console.log(sessionAnalytics)

  useEffect(() => {
    const newMap = new maplibregl.Map({
      container: 'map', // container ID
      style: {
        'version': 8,
        "glyphs": "https://fonts.openmaptiles.org/{fontstack}/{range}.pbf",
        'sources': {
          'raster-tiles': {
          'type': 'raster',
          'tiles': [
              'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'
            ],
            'tileSize': 256,
            'attribution': '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          }
        },
        'layers': [
          {
          'id': 'simple-tiles',
          'type': 'raster',
          'source': 'raster-tiles',
          'minzoom': 0,
          'maxzoom': 22
          }
        ]
      }, // style URL
      center: [-100.8603, 38.2700], // starting position [lng, lat]
      zoom: 3 // starting zoom
    });
    setMap(newMap);

    // First, I'll want to draw all the core starting information (bounds, center)
    newMap.on('load', () => {
      var boundsFeatureCollection = { type : "FeatureCollection", features : [] }
      sessionAnalytics.timeline.forEach(anEvent => {
        if(anEvent.bounds) {
          var bboxPolygon = turf.bboxPolygon([anEvent.bounds[0][0], anEvent.bounds[0][1], anEvent.bounds[1][0], anEvent.bounds[1][1]]);
          bboxPolygon.properties.time = anEvent.time;
          boundsFeatureCollection.features.push(bboxPolygon);
        }
      })

      newMap.addSource('bounds', {
        type : 'geojson',
        data : boundsFeatureCollection
      })
      newMap.addLayer({
        id: 'bounds',
        type : 'line',
        source : 'bounds',
        paint : {
          'line-opacity' : [
            'interpolate', ['linear'],
            ['number', ['get', 'time']],
            sessionAnalytics.timeline[0].time, 1,
            sessionAnalytics.timeline[sessionAnalytics.timeline.length-1].time, 0.1
          ],
          'line-color' : '#000'
        }
      })

      var bbox = turf.bbox(boundsFeatureCollection)
      newMap.fitBounds(bbox, { padding: 20 })

      var clicksFeatureCollection = { type : "FeatureCollection", features : [] }
      sessionAnalytics.timeline.forEach(anEvent => {
        if(anEvent.location) {
          var clickPoint = { type : "Feature", properties : { time : anEvent.time },
            geometry : {
              type : "Point",
              coordinates : anEvent.location
            }
          }
          clicksFeatureCollection.features.push(clickPoint)
        }
      })

      console.log(clicksFeatureCollection)

      newMap.addSource('clicks', {
        type : 'geojson',
        data : clicksFeatureCollection
      })
      newMap.addLayer({
        id: 'clicks',
        type : 'circle',
        source : 'clicks',
        paint : {
          'circle-radius' : 0,
          'circle-color' : '#FF0000',
          'circle-stroke-color' : '#CD5C5C',
          'circle-stroke-opacity' : 0.8,
          'circle-stroke-width' : 0
        }
      })

      var mousemoveFeatureCollection = { type : "FeatureCollection", features : [] }
      var mousemoveFeatureCollectionPoints = { type : "FeatureCollection", features : [] }
      sessionAnalytics.timeline.forEach(anEvent => {
        if(anEvent.line) {
          var mousemoveLine = { type : "Feature", properties : { time : anEvent.lineTimeline, startTime : anEvent.time },
            geometry : {
              type : "LineString",
              coordinates : anEvent.line
            }
          }
          mousemoveFeatureCollection.features.push(mousemoveLine)
          anEvent.line.forEach((point, i) => {
            var mousemovePoint = { type : "Feature", properties : { time : anEvent.lineTimeline[i] },
              geometry : {
                type : "Point",
                coordinates : point
              }
            }
            mousemoveFeatureCollectionPoints.features.push(mousemovePoint)
          })
        }
      })
      newMap.addSource('mousemoves', {
        type : 'geojson',
        data : mousemoveFeatureCollection
      })
      newMap.addLayer({
        id: 'mousemoves',
        type : 'line',
        source : 'mousemoves',
        paint : {
          'line-width' : 0
        }
      })
      newMap.addSource('mousemovesPoint', {
        type : 'geojson',
        data : mousemoveFeatureCollectionPoints
      })
      newMap.addLayer({
        id: 'mousemovesPoint',
        type : 'circle',
        source : 'mousemovesPoint',
        paint : {
          'circle-radius' : 0
        }
      })

      setSliderValue(sessionAnalytics.timeline[0].time)
    })
  }, [])

  useEffect(() => {
    if(map) {
      var firstTime = sessionAnalytics.timeline[0].time;
      var lastTime = sessionAnalytics.timeline[sessionAnalytics.timeline.length-1].time;
      sessionAnalytics.timeline.forEach(mapEvent => {
        var thisTime = mapEvent.time;
        if(sliderValue === thisTime) {

          // BOUNDS CHANGE
          if(mapEvent.bounds) {
            map.fitBounds(mapEvent.bounds)
            var mapboxExpression = [
              'interpolate', ['linear'],
              ['number', ['get', 'time']]
            ]
            if(firstTime !== thisTime) {
              mapboxExpression.push(parseInt(firstTime))
              mapboxExpression.push(0)
            }
            mapboxExpression.push(parseInt(thisTime))
            mapboxExpression.push(1)
            if(lastTime !== thisTime) {
              mapboxExpression.push(parseInt(lastTime))
              mapboxExpression.push(0)
            }
            map.setPaintProperty('bounds', 'line-opacity', mapboxExpression)
          }

          // CLICK CHANGE
          if(mapEvent.location) {
            console.log(thisTime)
            var mapboxExpression = [
                'case',
                ['<=', ['get', 'time'], thisTime], 5,
                0
            ]
            var mapboxExpressionCopy = JSON.parse(JSON.stringify(mapboxExpression))
            mapboxExpressionCopy[mapboxExpressionCopy.length-2] = 4;
            map.setPaintProperty('clicks', 'circle-radius', mapboxExpression)
            map.setPaintProperty('clicks', 'circle-stroke-width', mapboxExpressionCopy)
          }

          // MOUSEMOVE CHANGE
          const timeoutFunction = (time, timeElapsed) => {
            setTimeout(() => {
              var mapboxExpressionPoint = [
                  'case',
                  ['<=', ['get', 'time'], time], 3,
                  0
              ]
              map.setPaintProperty('mousemovesPoint', 'circle-radius', mapboxExpressionPoint)
            }, timeElapsed)
          }
          if(mapEvent.line) {
            mapEvent.lineTimeline.forEach((time, i) => {
              var timeElapsed = time - mapEvent.lineTimeline[0]
              timeoutFunction(time, timeElapsed)
            })
            var mapboxExpression = [
                'case',
                ['<=', ['get', 'startTime'], thisTime], 2,
                0
            ]
            map.setPaintProperty('mousemoves', 'line-width', mapboxExpression)
          }
        }
      })
    }
  }, [sliderValue])

  const playTimeline = () => {
    var startTime = parseInt(sessionAnalytics.timeline[0].time)
    currentInterval = setInterval(() => {
      currentTimePlaying.current = currentTimePlaying.current + 20;
      setUpdateTime(currentTimePlaying.current)
    }, 20);

    sessionAnalytics.timeline.forEach((mapEvent, i) => {
      var thisTime = mapEvent.time ? parseInt(mapEvent.time) : parseInt(mapEvent.timeStart);
      var thisTimeout = setTimeout(() => {
        setSliderValue(thisTime)
        if(i === sessionAnalytics.timeline.length - 1) {
          clearInterval(currentInterval);
          currentInterval = false;
        }
      }, thisTime-startTime)
      currentTimeouts.push(thisTimeout);
    })

    setIsPlaying(true);
  }

  const stopTimeline = () => {
    currentTimeouts.forEach(timeout => {
      clearTimeout(timeout);
    })
    currentTimeouts = [];
    clearInterval(currentInterval);
    currentInterval = false;

    setIsPlaying(false);
  }

  const tipFormatter = (value) => {
    var thisElement = sessionAnalytics.timeline.find(anEvent => anEvent.time === value)
    var thisTimeLabel = ((thisElement.time - parseInt(sessionAnalytics.timeline[0].time))/1000).toFixed(2) + 's'
    setDescription(thisElement.description)
    return `${thisTimeLabel} ${thisElement.description}`
  }

  var marks = {}
  if(sessionAnalytics.timeline) {
    sessionAnalytics.timeline.forEach(mapEvent => {
      var thisTime = mapEvent.time
      marks[thisTime] = ''
    })
  }

  var fromTime = new Date(sessionAnalytics.timeline[0].time).toLocaleDateString() + ', ' + new Date(sessionAnalytics.timeline[0].time).toLocaleTimeString();
  var toTime = new Date(sessionAnalytics.timeline[sessionAnalytics.timeline.length - 1].time).toLocaleDateString() + ', ' + new Date(sessionAnalytics.timeline[sessionAnalytics.timeline.length - 1].time).toLocaleTimeString()
  var totalTime = new Date(sessionAnalytics.timeline[sessionAnalytics.timeline.length - 1].time).getTime() - new Date(sessionAnalytics.timeline[0].time).getTime()

  return (
    <div className="flex flex-col col-span-full xl:col-span-12 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100 flex items-center">
        <button onClick={() => isPlaying ? stopTimeline() : playTimeline()} className={`btn bg-gray-700 hover:bg-gray-600 text-white`}>
          <span className="hidden xs:block">{isPlaying ? 'Stop playback' : 'Play User Session From Start'}</span>
        </button>
        <h2 style={{float:'left', marginLeft: '15px'}} className="font-semibold text-gray-800">{(updateTime/1000).toFixed(2) + 's'} (Last event: {description})</h2>
        <div style={{flex : 'auto', textAlign : 'right'}}>
          <div className="text-xs inline-flex font-medium bg-indigo-100 text-indigo-600 rounded-full text-center px-2.5 py-1">{fromTime} -- {toTime} ({formatMS(totalTime)})</div>
        </div>
      </header>
      <div>
        <div id="map" style={{width: '100%', height: '400px'}}/>
      </div>
      <div>
        <div style={{"position" : "relative"}}>
          <SliderWithTooltip
            style={{width: '90%', marginLeft:'5%', marginTop : '20px'}}
            tipFormatter={tipFormatter}
            tipProps={{ visible : true }}
            value={sliderValue}
            min={sessionAnalytics.timeline[0].time}
            max={sessionAnalytics.timeline[sessionAnalytics.timeline.length-1].time}
            marks={marks}
            step={null}
            onChange={(e) => setSliderValue(e)}
          />
        </div>
      </div>
      <div style={{margin: 10, textAlign: 'right'}}>
      </div>
    </div>
  );
}

export default AnalyticsMap;
