import React, { useRef, useEffect, useState } from 'react';
import _ from 'lodash';

import {
  Chart, LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip,
} from 'chart.js';
import 'chartjs-adapter-moment';

// Import utilities
import { tailwindConfig, formatValue } from '../utils/Utils';

Chart.register(LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip);

function LineChart01({
  data,
  width,
  height
}) {

  const canvas = useRef(null);
  const [lastData, setLastData] = useState(false)
  const [currentChart, setCurrentChart] = useState(false);

  useEffect(() => {
    if(!_.isEqual(data, lastData)) {
      setLastData(data);
      if(currentChart) {
        currentChart.destroy();
      }
      const ctx = canvas.current;
      // eslint-disable-next-line no-unused-vars
      const chart = new Chart(ctx, {
        type: 'line',
        data: data,
        options: {
          chartArea: {
            backgroundColor: tailwindConfig().theme.colors.gray[50],
          },
          layout: {
            padding: 20,
          },
          scales: {
            y: {
              display: false,
              beginAtZero: true,
            },
            x: {
              type: 'time',
              time: {
                parser: 'MM-DD-YYYY',
                unit: 'month',
              },
              display: false,
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                title: () => false, // Disable tooltip title
                label: (context) => parseInt(context.parsed.y).toString(),
              },
            },
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: 'nearest',
          },
          maintainAspectRatio: false,
          resizeDelay: 200,
        },
      });
      setCurrentChart(chart)
    }
  }, [data]);

  return (
    <canvas ref={canvas} width={width} height={height}></canvas>
  );
}

export default LineChart01;
