
const API_URL = process.env.REACT_APP_URL;
export const SET_LOGIN = 'SET_LOGIN';
export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_LOGOUT = 'SET_LOGOUT';
export const CLEAR_MSG = 'CLEAR_MSG';

export function signup(email, password, account_name) {
    return (dispatch) => {
        // get page translations
        return fetch(`${API_URL}/api/accounts/signup`, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify({email, password, account_name})
        })
        .then(resp => resp.json()).then(response => {
            dispatch(setMessage(response))
        })
        .catch((error) => console.log("SIGNIN ERROR: ", error));
    };
}

export function loginFromToken(email, token) {
    return (dispatch) => {
        // get page translations
        return fetch(`${API_URL}/api/accounts/authenticate/${email}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer: ${token}`
            },
            // body: JSON.stringify({email})
        })
        .then(resp => resp.json()).then(response => {
          console.log(response);
            if(response.msg) {
              localStorage.removeItem('mapster-dashboard-email')
              localStorage.removeItem('mapster-dashboard-token')
              dispatch(setMessage(response))
              window.location = '/signin';
            } else {
              dispatch(setLogin(response))
            }
        })
        .catch((error) => console.log("QUICKAUTH ERROR: ", error));
    };
}

export function emailConfirm(token) {
    return (dispatch) => {
        // get page translations
        return fetch(`${API_URL}/api/accounts/confirm_email`, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify({token})
        })
        .then(resp => resp.json()).then(response => {
            dispatch(setMessage({ msg : response.success ? "Email confirmed. Please log in." : response.msg }))
        })
        .catch((error) => console.log("SIGNIN ERROR: ", error));
    };
}

export function login(email, password) {
    return (dispatch) => {
        // get page translations
        return fetch(`${API_URL}/api/accounts/login`, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify({email, password})
        })
        .then(resp => resp.json()).then(response => {
            if(response.msg) {
              dispatch(setMessage(response))
            } else {
              dispatch(setLogin(response))
            }
        })
        .catch((error) => console.log("SIGNIN ERROR: ", error));
    };
}

export function setPassword(password, token) {
    return (dispatch) => {
        // get page translations
        return fetch(`${API_URL}/api/accounts/update_password`, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify({password, token})
        })
        .then(resp => resp.json()).then(response => {
          console.log(response);
            if(response.msg) {
              dispatch(setMessage(response))
            } else {
              dispatch(setMessage({ msg : 'Password updated. Please log in again.'}))
            }
        })
        .catch((error) => console.log("PW ERROR: ", error));
    };
}

export function resetPassword(email) {
    return (dispatch) => {
        // get page translations
        return fetch(`${API_URL}/api/accounts/reset/password`, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify({email})
        })
        .then(resp => resp.json()).then(response => {
            dispatch(setMessage(response))
        })
        .catch((error) => console.log("SIGNIN ERROR: ", error));
    };
}

export function setMessage(payload){
    return {
        type: SET_MESSAGE,
        payload: payload
    };
}

export function setLogin(payload){
  console.log(payload)
    return {
        type: SET_LOGIN,
        payload: payload
    };
}

export function setLogout(){
    return {
        type: SET_LOGOUT,
        payload: {}
    };
}

export function clearMsg(){
    return {
        type: CLEAR_MSG,
        payload: {}
    };
}
