const API_URL = process.env.REACT_APP_URL;
export const SET_SESSIONS = 'SET_SESSIONS';
export const SET_SESSION_ANALYTICS = 'SET_SESSION_ANALYTICS';
export const SET_ACTIVE_SESSIONS = 'SET_ACTIVE_SESSIONS';

export function getSessions(account_id, startTime, endTime, page) {
    return (dispatch) => {
        return fetch(`${API_URL}/api/sessions/list/${account_id}/${page}/?startTime=${startTime}&endTime=${endTime}`)
        .then(resp => resp.json()).then(response => {
            dispatch(setSessions(response))
        })
        .catch((error) => console.log("USER LIST ERROR: ", error));
    };
}

export function getSessionAnalytics(map_id, session_id) {
    return (dispatch) => {
        return fetch(`${API_URL}/api/sessions/analytics/${map_id}/${session_id}`)
        .then(resp => resp.json()).then(response => {
            dispatch(setSessionAnalytics(response.analytics))
        })
        .catch((error) => console.log("USER LIST ERROR: ", error));
    };
}

export function getActiveSessions(map_id, account_id) {
    return (dispatch) => {
        var query = `${account_id}`;
        if(map_id) {
          query += `/${map_id}`;
        } else {
          query += `/none`;
        }
        return fetch(`${API_URL}/api/sessions/list-active/${query}`)
        .then(resp => resp.json()).then(response => {
            dispatch(setActiveSessions(response.sessions))
        })
        .catch((error) => console.log("SESSION ACTIVE ERROR: ", error));
    };
}

export function setSessions(payload){
    return {
        type: SET_SESSIONS,
        payload: payload
    };
}

export function setSessionAnalytics(payload){
    return {
        type: SET_SESSION_ANALYTICS,
        payload: payload
    };
}

export function setActiveSessions(payload){
    return {
        type: SET_ACTIVE_SESSIONS,
        payload: payload
    };
}
