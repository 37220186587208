import React from 'react';
import LineChart from '../../charts/LineChart03';

// Import utilities
import { tailwindConfig, hexToRGB } from '../../utils/Utils';

function AnalyticsCard01({ analytics }) {

  var labels = analytics.loadStats.loadStatsPerPeriod.map(period => period.startTime);

  const chartData = {
    labels: labels,
    datasets: [
      // Indigo line
      {
        label: 'Current',
        data: analytics.loadStats.loadStatsPerPeriod.map(period => period.sessionNumber),
        fill: true,
        backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.08)`,
        borderColor: tailwindConfig().theme.colors.indigo[500],
        borderWidth: 2,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
      },
      // Gray line
      {
        label: 'Previous',
        data: analytics.loadStats.loadStatsPerPriorPeriod.map(period => period.sessionNumber),
        borderColor: tailwindConfig().theme.colors.gray[300],
        fill: false,
        borderWidth: 2,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: tailwindConfig().theme.colors.gray[300],
      },
    ],
  };

  return (
    <div className="flex flex-col col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100 flex items-center">
        <h2 className="font-semibold text-gray-800">Analytics</h2>
      </header>
      <div className="px-5 py-1">
        <div className="flex flex-wrap">
          {/* Unique Visitors */}
          <div className="flex items-center py-2">
            <div className="mr-5">
              <div className="flex items-center">
                <div className="text-3xl font-bold text-gray-800 mr-2">{analytics.loadStats.users}</div>
                {/* <div className="text-sm font-medium text-green-500">+49%</div> */}
              </div>
              <div className="text-sm text-gray-500">Unique Users</div>
            </div>
            <div className="hidden md:block w-px h-8 bg-gray-200 mr-5" aria-hidden="true"></div>
          </div>
          {/* Total Pageviews */}
          <div className="flex items-center py-2">
            <div className="mr-5">
              <div className="flex items-center">
                <div className="text-3xl font-bold text-gray-800 mr-2">{analytics.loadStats.sessions}</div>
                {/* <div className="text-sm font-medium text-green-500">+49%</div> */}
              </div>
              <div className="text-sm text-gray-500">Map Sessions</div>
            </div>
            <div className="hidden md:block w-px h-8 bg-gray-200 mr-5" aria-hidden="true"></div>
          </div>
          {/* Bounce Rate */}
          <div className="flex items-center py-2">
            <div className="mr-5">
              <div className="flex items-center">
                <div className="text-3xl font-bold text-gray-800 mr-2">{analytics.errorStats.count}</div>
                {/* <div className="text-sm font-medium text-yellow-500">-7%</div> */}
              </div>
              <div className="text-sm text-gray-500">Map Errors</div>
            </div>
            <div className="hidden md:block w-px h-8 bg-gray-200 mr-5" aria-hidden="true"></div>
          </div>
          {/* Visit Duration*/}
          <div className="flex items-center">
            <div>
              <div className="flex items-center">
                <div className="text-3xl font-bold text-gray-800 mr-2">{formatUserTime(analytics.userTimes.avgSessionTime/(analytics.loadStats.sessions/analytics.loadStats.users))}</div>
                {/* <div className="text-sm font-medium text-yellow-500">-7%</div> */}
              </div>
              <div className="text-sm text-gray-500">Map Use Avg Duration</div>
            </div>
          </div>
        </div>
      </div>
      {/* Chart built with Chart.js 3 */}
      <div className="flex-grow">
        {/* Change the height attribute to adjust the chart height */}
        <LineChart data={chartData} width={800} height={300} />
      </div>
    </div>
  );
}

function formatUserTime(totalMilliseconds) {
  var second = 1000;
  var minute = second * 60;
  var hour = minute * 60;
  if(totalMilliseconds > hour) {
    var totalHours = Math.floor(totalMilliseconds / hour);
    var extraMinutes = Math.floor((totalMilliseconds - (totalHours * hour)) / minute);
    return totalHours + 'h ' + extraMinutes + 'm'
  }
  if(totalMilliseconds > minute) {
    var totalMinutes = Math.floor(totalMilliseconds / minute);
    var extraSeconds = Math.floor((totalMilliseconds - (totalMinutes * minute)) / second);
    return totalMinutes + 'm ' + extraSeconds + 's'
  }
  if(totalMilliseconds > second) {
    var totalSeconds = Math.floor(totalMilliseconds / second);
    var extraMilliseconds = Math.floor(totalMilliseconds - (totalSeconds * second));
    return totalSeconds + 's ' + extraMilliseconds + 'ms'
  }
}

export default AnalyticsCard01;
