import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import WelcomeBanner from '../partials/dashboard/WelcomeBanner';
import DashboardAvatars from '../partials/dashboard/DashboardAvatars';
import FilterButton from '../components/DropdownFilter';
import Datepicker from '../components/Datepicker';
import Dashboard24HourSummary from '../partials/dashboard/Dashboard24HourSummary';
import DashboardCard01 from '../partials/dashboard/DashboardCard01';
import DashboardCard02 from '../partials/dashboard/DashboardCard02';
import DashboardCard03 from '../partials/dashboard/DashboardCard03';
import DashboardCard04 from '../partials/dashboard/DashboardCard04';
import DashboardCard05 from '../partials/dashboard/DashboardCard05';
import DashboardCard06 from '../partials/dashboard/DashboardCard06';
import DashboardCard07 from '../partials/dashboard/DashboardCard07';
import DashboardCard08 from '../partials/dashboard/DashboardCard08';
import DashboardCard09 from '../partials/dashboard/DashboardCard09';
import DashboardCard10 from '../partials/dashboard/DashboardCard10';
import DashboardCard11 from '../partials/dashboard/DashboardCard11';

import { getSummary, getRecentActivity } from '../actions/actions_dashboard'
import { getActiveUsers } from '../actions/actions_users'

function Dashboard({ dispatch, recentActivity, summary, activeUsers, startTime, endTime, currentAccount }) {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date().getTime())

  useEffect(() => {
    if(currentAccount) {
      doFetch()
      setInterval(() => {
        setCurrentTime(new Date().getTime());
      }, 10000);
    }
  }, [currentAccount])

  useEffect(() => {
    if(currentAccount) {
      dispatch(getSummary(currentAccount.id, startTime, endTime))
    }
  }, [currentAccount, startTime, endTime])

  useEffect(() => {
    if(currentAccount) {
      // dispatch(getRecentActivity(false, currentAccount.id, startTime, endTime))
      dispatch(getActiveUsers(false, currentAccount.id))
    }
  }, [currentTime])

  const doFetch = () => {
    dispatch(getRecentActivity(false, currentAccount.id, startTime, endTime))
    dispatch(getSummary(currentAccount.id, startTime, endTime))
    dispatch(getActiveUsers(currentAccount.id))
  }

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Welcome banner */}
            {currentAccount ?
              <WelcomeBanner currentAccount={currentAccount} />
            :  <WelcomeBanner/>}
            {/* Right: Actions */}
            <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">


              <div className="relative">
                <div onClick={() => doFetch()} className="form-input text-gray-500 hover:text-gray-600 font-medium focus:border-gray-300 w-10 h-10" style={{cursor: "pointer"}}>
                  <div className="left-auto items-center" style={{marginTop: 3}}>
                    <svg className="w-4 h-4 fill-current text-gray-500" viewBox="0 0 24 24">
                      <path d="M13.5 2c-5.621 0-10.211 4.443-10.475 10h-3.025l5 6.625 5-6.625h-2.975c.257-3.351 3.06-6 6.475-6 3.584 0 6.5 2.916 6.5 6.5s-2.916 6.5-6.5 6.5c-1.863 0-3.542-.793-4.728-2.053l-2.427 3.216c1.877 1.754 4.389 2.837 7.155 2.837 5.79 0 10.5-4.71 10.5-10.5s-4.71-10.5-10.5-10.5z"/>
                    </svg>
                  </div>
                </div>
              </div>
              {/* Datepicker built with flatpickr */}
              <Datepicker align="right" />

            </div>

            {/* Dashboard actions */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">

              {/* Left: Avatars */}
              {/* <DashboardAvatars /> */}


              {/* Right: Actions */}
              {/* <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2"> */}
                {/* Filter button */}
                {/* <FilterButton align="right" /> */}
                {/* Datepicker built with flatpickr */}
                {/* <Datepicker align="right" /> */}
                {/* Add view button */}
                {/* <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white">
                    <svg className="w-4 h-4 fill-current opacity-50 flex-shrink-0" viewBox="0 0 16 16">
                        <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                    </svg>
                    <span className="hidden xs:block ml-2">Add view</span>
                </button>
              </div>
              */}

            </div>

            {/* Cards */}
            {/* summary.map(summaryData => {
              return (
                <div>
                  <h2 className="text-lg font-semibold text-gray-800 mb-2">{summaryData.map_name}</h2>
                  <div className="grid grid-cols-12 gap-6">
                      <Dashboard24HourSummary
                        title={"Sessions (Views)"}
                        property={'sessionNumber'}
                        stats24HourPerHour={summaryData.stats24HourPerHour} />
                      <Dashboard24HourSummary
                        title={"Unique Users"}
                        property={'userNumber'}
                        stats24HourPerHour={summaryData.stats24HourPerHour} />
                      <Dashboard24HourSummary
                        title={"Average Load Times"}
                        property={'avgLoadTime'}
                        stats24HourPerHour={summaryData.stats24HourPerHour} />
                  </div>
                </div>
              )
            })*/}

            <div className="grid grid-cols-12 gap-6">

              {/* Line chart (Views) */}
              <DashboardCard01 summary={summary} />
              {/* Line chart (Users) */}
              <DashboardCard02 summary={summary} />
              {/* Line chart (Load Times) */}
              <DashboardCard03 summary={summary} />
              {/* Bar chart (Direct vs Indirect) */}
              {/* <DashboardCard04 /> */}
              {/* Line chart (Real Time Value) */}
              <DashboardCard05 activeUsers={activeUsers} />
              {/* Card (Recent Activity) */}
              <DashboardCard10 recentActivity={recentActivity} />
              {/* Doughnut chart (Top Countries) */}
              <DashboardCard06 summary={summary} />
              {/* Table (Top Channels) */}
              <DashboardCard07 summary={summary} />
              {/* Line chart (Sales Over Time) */}
              <DashboardCard08 summary={summary} />
              {/* Stacked bar chart (Sales VS Refunds) */}
              {/* <DashboardCard09 /> */}
              {/* Card (Income/Expenses) */}
              {/* <DashboardCard11 /> */}

            </div>

          </div>
        </main>

      </div>

    </div>
  );
}

const dashboardState = (state)=>({
  summary: state.dashboard.summary,
  activeUsers : state.users.activeUsers,
  startTime: state.date.startTime,
  endTime: state.date.endTime,
  recentActivity : state.dashboard.recentActivity,
  currentAccount : state.account.currentAccount
})

export default connect(dashboardState)(Dashboard)
