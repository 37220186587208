import React from 'react';
import LineChart from '../../charts/LineChart03';

// Import utilities
import { formatMS } from '../../utils/Format';
import { tailwindConfig, hexToRGB } from '../../utils/Utils';

function AnalyticsCard01({ sessionAnalytics }) {

  return (
    <div className="flex flex-col col-span-6 xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100 flex items-center">
        <h2 className="font-semibold text-gray-800">Map</h2>
      </header>
      <div className="px-5 py-1">
        <div className="flex flex-wrap">
          {/* Unique Visitors */}
          <div className="flex items-center py-2">
            <div className="mr-5">
              <div className="flex items-center">
                <div className="w-9 h-9 rounded-full flex-shrink-0 bg-indigo-500 my-2 mr-3">
                  <svg xmlns="http://www.w3.org/2000/svg" style={{marginLeft:4,marginTop:4}} className="w-7 h-7 text-indigo-50" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFF" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <line x1="12" y1="6" x2="12" y2="3" />
                    <line x1="16.25" y1="7.75" x2="18.4" y2="5.6" />
                    <line x1="18" y1="12" x2="21" y2="12" />
                    <line x1="16.25" y1="16.25" x2="18.4" y2="18.4" />
                    <line x1="12" y1="18" x2="12" y2="21" />
                    <line x1="7.75" y1="16.25" x2="5.6" y2="18.4" />
                    <line x1="6" y1="12" x2="3" y2="12" />
                    <line x1="7.75" y1="7.75" x2="5.6" y2="5.6" />
                  </svg>
                </div>
                <div className="text-3xl font-bold text-gray-800 mr-2">{sessionAnalytics.stats.renderStats.count}</div>
                <div className="text-sm text-gray-500">Renders</div>
              </div>
            </div>
            <div className="hidden md:block w-px h-8 bg-gray-200 mr-5" aria-hidden="true"></div>
            <div className="mr-5">
              <div className="flex items-center">
                <div className="text-1xl font-bold text-gray-800 mr-2">{formatMS(sessionAnalytics.stats.renderStats.avgTime)}</div>
                <div className="text-sm text-gray-500">Render Avg Time</div>
              </div>
            </div>
            <div className="hidden md:block w-px h-8 bg-gray-200 mr-5" aria-hidden="true"></div>
            <div className="mr-5">
              <div className="flex items-center">
                <div className="text-1xl font-bold text-gray-800 mr-2">{sessionAnalytics.stats.errorStats.count}</div>
                <div className="text-sm text-gray-500">Errors</div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="flex items-center py-2">
            <div className="mr-5">
              <div className="flex items-center">
                <div className="w-9 h-9 rounded-full flex-shrink-0 bg-light-blue-500 my-2 mr-3">
                  <svg xmlns="http://www.w3.org/2000/svg" style={{marginLeft:4,marginTop:4}} className="w-7 h-7 fill-current text-light-blue-50" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFF" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M16 16v2a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h2v-2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-2" />
                    <polyline points="10 8 8 8 8 10" />
                    <polyline points="8 14 8 16 10 16" />
                    <polyline points="14 8 16 8 16 10" />
                    <polyline points="16 14 16 16 14 16" />
                  </svg>
                </div>
                <div className="text-3xl font-bold text-gray-800 mr-2">{sessionAnalytics.stats.layerStats.changes}</div>
                <div className="text-sm text-gray-500">Layer Changes</div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="flex items-center py-2">
            <div className="mr-5">
              <div className="flex items-center">
                <div className="w-9 h-9 rounded-full flex-shrink-0 bg-red-500 my-2 mr-3">
                  <svg className="w-9 h-9 fill-current text-red-50" viewBox="0 0 36 36">
                    <path d="M25 24H11a1 1 0 01-1-1v-5h2v4h12v-4h2v5a1 1 0 01-1 1zM14 13h8v2h-8z" />
                  </svg>
                </div>
                <div className="text-3xl font-bold text-gray-800 mr-2">{sessionAnalytics.stats.loadStats.center[0].toFixed(3)}, {sessionAnalytics.stats.loadStats.center[1].toFixed(3)}</div>
                <div className="text-sm text-gray-500">Center</div>
              </div>
            </div>
            <div className="hidden md:block w-px h-8 bg-gray-200 mr-5" aria-hidden="true"></div>
            <div className="mr-5">
              <div className="flex items-center">
                <div className="text-1xl font-bold text-gray-800 mr-2">{sessionAnalytics.stats.loadStats.zoom}</div>
                <div className="text-sm text-gray-500">Zoom</div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap">
          <div className="flex items-center py-2">
            <div className="mr-5">
              <div className="flex items-center">
                <div className="text-1xl font-bold text-gray-800 mr-2">{sessionAnalytics.stats.loadStats.height}px</div>
                <div className="text-sm text-gray-500">Height</div>
              </div>
            </div>
            <div className="hidden md:block w-px h-8 bg-gray-200 mr-5" aria-hidden="true"></div>
            <div className="mr-5">
              <div className="flex items-center">
                <div className="text-1xl font-bold text-gray-800 mr-2">{sessionAnalytics.stats.loadStats.width}px</div>
                <div className="text-sm text-gray-500">Width</div>
              </div>
            </div>
            <div className="hidden md:block w-px h-8 bg-gray-200 mr-5" aria-hidden="true"></div>
            <div className="mr-5">
              <div className="flex items-center">
                <div className="text-1xl font-bold text-gray-800 mr-2">{sessionAnalytics.stats.loadStats.url}</div>
                <div className="text-sm text-gray-500">URL</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function formatUserTime(totalMilliseconds) {
  var second = 1000;
  var minute = second * 60;
  var hour = minute * 60;
  if(totalMilliseconds > hour) {
    var totalHours = Math.floor(totalMilliseconds / hour);
    var extraMinutes = Math.floor((totalMilliseconds - (totalHours * hour)) / minute);
    return totalHours + 'h ' + extraMinutes + 'm'
  }
  if(totalMilliseconds > minute) {
    var totalMinutes = Math.floor(totalMilliseconds / minute);
    var extraSeconds = Math.floor((totalMilliseconds - (totalMinutes * minute)) / second);
    return totalMinutes + 'm ' + extraSeconds + 's'
  }
  if(totalMilliseconds > second) {
    var totalSeconds = Math.floor(totalMilliseconds / second);
    var extraMilliseconds = Math.floor(totalMilliseconds - (totalSeconds * second));
    return totalSeconds + 's ' + extraMilliseconds + 'ms'
  }
}

export default AnalyticsCard01;
