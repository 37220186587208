import React from 'react';
import BarChart from '../../charts/BarChart04';

// Import utilities
import { tailwindConfig } from '../../utils/Utils';

function AnalyticsCard04({ analytics }) {

  var startAndEndTimePeriods = [analytics.loadStats.loadStatsPerPeriod[0].startTime, analytics.loadStats.loadStatsPerPeriod[analytics.loadStats.loadStatsPerPeriod.length - 1].startTime]
  var timeDivision = (startAndEndTimePeriods[1] - startAndEndTimePeriods[0])/4

  var times = [];
  var labels = [];
  for(var i=0; i<=3; i++) {
    times.push(startAndEndTimePeriods[0] + (timeDivision * i))
    labels.push(new Date(startAndEndTimePeriods[0] + (timeDivision * i)).toISOString().slice(0, 10))
  }

  var desktopData = [0, 0, 0, 0];
  var mobileData = [0, 0, 0, 0];
  times.forEach((timeLabel, i) => {
    analytics.loadStats.loadStatsPerPeriod.forEach(periodStats => {
      if(periodStats.startTime >= timeLabel && periodStats.startTime <= (timeLabel+timeDivision)) {
        desktopData[i] += periodStats.desktop;
        mobileData[i] += periodStats.mobile;
      }
    })
  })

  const chartData = {
    labels: times,
    datasets: [
      // Blue bars
      {
        label: 'Desktop Loads',
        data: desktopData,
        backgroundColor: tailwindConfig().theme.colors.indigo[500],
        hoverBackgroundColor: tailwindConfig().theme.colors.indigo[600],
        categoryPercentage: 0.66,
      },
      // Light blue bars
      {
        label: 'Mobile Loads',
        data: mobileData,
        backgroundColor: tailwindConfig().theme.colors['light-blue'][400],
        hoverBackgroundColor: tailwindConfig().theme.colors['light-blue'][500],
        categoryPercentage: 0.66,
      },
    ],
  };

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
          <h2 className="font-semibold text-gray-800">Desktop vs Mobile</h2>
      </header>
      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}
      <BarChart data={chartData} width={595} height={248} />
    </div>
  );
}

export default AnalyticsCard04;
