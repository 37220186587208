import { combineReducers } from "redux";
import reducer_account from "./reducer_account";
import reducer_maps from './reducer_maps';
import reducer_users from './reducer_users';
import reducer_dashboard from './reducer_dashboard';
import reducer_sessions from './reducer_sessions';
import reducer_date from './reducer_date';

const rootReducer = combineReducers({
    account: reducer_account,
    maps : reducer_maps,
    dashboard : reducer_dashboard,
    users : reducer_users,
    sessions : reducer_sessions,
    date : reducer_date
});

export default rootReducer;
