import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import Select from '../partials/actions/Select';
import Datepicker from '../components/Datepicker';
import AnalyticsMap from '../partials/sessionAnalytics/AnalyticsMap';
import AnalyticsCard01 from '../partials/sessionAnalytics/AnalyticsCard01';
import AnalyticsCard02 from '../partials/sessionAnalytics/AnalyticsCard02';
import AnalyticsCard03 from '../partials/sessionAnalytics/AnalyticsCard03';
import AnalyticsCard04 from '../partials/sessionAnalytics/AnalyticsCard04';
import AnalyticsCard05 from '../partials/sessionAnalytics/AnalyticsCard05';
import AnalyticsCard06 from '../partials/sessionAnalytics/AnalyticsCard06';
import AnalyticsCard11 from '../partials/sessionAnalytics/AnalyticsCard11';

import { getSessionAnalytics, setSessionAnalytics } from '../actions/actions_sessions'

function SessionsAnalytics({ dispatch, sessionAnalytics }) {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentSession, setCurrentSession] = useState(false);

  useEffect(() => {
    dispatch(setSessionAnalytics(false));
    let searchParams = new URLSearchParams(window.location.search)
    if(searchParams.has('map_id') && searchParams.has('session_id')) {
      dispatch(getSessionAnalytics(searchParams.get('map_id'), searchParams.get('session_id')))
      setCurrentSession(searchParams.get('session_id'))
    }
  }, [])

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">

              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">Session Analytics</h1>
                <p>A single session is any unique time that the page loads with the map for a given user. You can press Play below to replay the user's entire session, or explore statistics.</p>
              </div>

            </div>

            {/* Cards */}
            {sessionAnalytics && currentSession ?
              <div className="grid grid-cols-12 gap-6">

                <AnalyticsMap sessionAnalytics={sessionAnalytics} />
                <AnalyticsCard03 sessionAnalytics={sessionAnalytics} />
                <AnalyticsCard04 sessionAnalytics={sessionAnalytics} />
                <AnalyticsCard05 sessionAnalytics={sessionAnalytics} />
                <AnalyticsCard11 sessionAnalytics={sessionAnalytics} />
                <AnalyticsCard06 sessionAnalytics={sessionAnalytics} />

              </div>
            : false}

          </div>
        </main>

      </div>

    </div>
  );
}

const analyticsState = (state)=>({
  sessionAnalytics : state.sessions.sessionAnalytics
})

export default connect(analyticsState)(SessionsAnalytics)
