import React from 'react';
import { Link } from 'react-router-dom';

function AnalyticsCard05({ analytics }) {

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">Top Locations</h2>
      </header>
      <div className="flex-grow p-3">
        <div className="flex flex-col h-full">
          {/* Card content */}
          <div className="flex-grow">
            <ul className="flex justify-between text-xs uppercase text-gray-400 font-semibold px-2 space-x-2">
              <li>City</li>
              <li>Sessions</li>
            </ul>

            <ul className="space-y-1 text-sm text-gray-800 mt-3 mb-4">
              {analytics.loadStats.locations.map(location => {
                return (
                  <li className="relative px-2 py-1">
                    <div className="absolute inset-0 bg-indigo-100" aria-hidden="true" style={{width: `${(location.count/analytics.loadStats.sessions) * 100}%`}}></div>
                    <div className="relative flex justify-between space-x-2">
                      <div>{location.name}</div>
                      <div className="font-medium">{location.count}</div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
          {/* Card footer */}
          {/* <div className="text-center pt-4 pb-1 border-t border-gray-100">
            <Link className="text-sm font-medium text-indigo-500 hover:text-indigo-600" to="#0">Locations Report -&gt;</Link>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default AnalyticsCard05;
