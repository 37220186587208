const { SET_MAPS, ADD_MAP, REMOVE_MAP } = require("../actions/actions_maps");

const initialState = {
    allMaps : []
}

const reducer_maps = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_MAPS:
            var newState = JSON.parse(JSON.stringify(state))
            newState.allMaps = payload;
            return newState
        case ADD_MAP:
            var newState = JSON.parse(JSON.stringify(state))
            newState.allMaps.push(payload);
            return newState
        case REMOVE_MAP:
            var newState = JSON.parse(JSON.stringify(state))
            newState.allMaps = [];
            state.allMaps.forEach(map => {
              if(map.map_id !== payload) {
                newState.allMaps.push(map)
              }
            })
            return newState
        default:
            return state
    }
}
export default reducer_maps;
