import React from 'react';
import { NavLink } from 'react-router-dom';
import LineChart from '../../charts/LineChart03';

// Import utilities
import { tailwindConfig, hexToRGB } from '../../utils/Utils';

function AnalyticsCard01({ userAnalytics }) {

  var os = [];
  for(var prop in userAnalytics.stats.loadStats.os) {
    os.push({ value : prop, sessions : userAnalytics.stats.loadStats.os[prop]})
  }
  var locations = [];
  userAnalytics.stats.loadStats.locations.forEach(location => {
    var findLocationIndex = locations.findIndex(thisLocation => thisLocation.value === location.city)
    if(findLocationIndex > -1) {
      locations[findLocationIndex].sessions += 1;
    } else {
      locations.push({ value : location.city, sessions : 1 })
    }
  })
  var platforms = [];
  for(var prop in userAnalytics.stats.loadStats.platforms) {
    platforms.push({ value : prop, sessions : userAnalytics.stats.loadStats.platforms[prop]})
  }
  var urls = [];
  for(var prop in userAnalytics.stats.loadStats.urls) {
    urls.push({ value : prop, sessions : userAnalytics.stats.loadStats.urls[prop]})
  }


  return (
    <div className="col-span-6 sm:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">User Information</h2>
      </header>
      <div className="p-3">

        {/* Table */}
        <div className="overflow-x-auto" style={{maxHeight: 400, overflowY : 'scroll'}}>
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Type</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Value</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Sessions</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-100">
              {os.map(thisInfo => {
                return (
                  <tr>
                    <td className="p-2 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="font-medium text-gray-800">OS</div>
                      </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="font-medium text-gray-800">{thisInfo.value}</div>
                      </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="font-medium text-gray-800">{thisInfo.sessions}</div>
                      </div>
                    </td>
                  </tr>
                )
              })}
              {locations.map(thisInfo => {
                return (
                  <tr>
                    <td className="p-2 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="font-medium text-gray-800">Location</div>
                      </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="font-medium text-gray-800">{thisInfo.value}</div>
                      </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="font-medium text-gray-800">{thisInfo.sessions}</div>
                      </div>
                    </td>
                  </tr>
                )
              })}
              {platforms.map(thisInfo => {
                return (
                  <tr>
                    <td className="p-2 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="font-medium text-gray-800">Platform</div>
                      </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="font-medium text-gray-800">{thisInfo.value}</div>
                      </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="font-medium text-gray-800">{thisInfo.sessions}</div>
                      </div>
                    </td>
                  </tr>
                )
              })}
              {urls.map(thisInfo => {
                return (
                  <tr>
                    <td className="p-2 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="font-medium text-gray-800">URL</div>
                      </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="font-medium text-gray-800">{thisInfo.value}</div>
                      </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="font-medium text-gray-800">{thisInfo.sessions}</div>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>

        </div>
      </div>
    </div>
  );
}

export default AnalyticsCard01;
