import React, { useState, useEffect } from 'react';
import Tooltip from '../../components/Tooltip';
import RealtimeChart from '../../charts/RealtimeChart';

// Import utilities
import { tailwindConfig, hexToRGB } from '../../utils/Utils';

function DashboardCard05({ activeUsers }) {

  const [ activeUserData, setActiveUserData ] = useState([]);

  useEffect(() => {
    var newActiveUserData = JSON.parse(JSON.stringify(activeUserData))
    newActiveUserData.push({
      time : new Date().getTime(),
      users : activeUsers.length
    })
    if(newActiveUserData.length > 10) {
      newActiveUserData.shift()
    }
    setActiveUserData(newActiveUserData)
  }, [activeUsers])

  const chartData = {
    labels: activeUserData && activeUserData.map(data => data.time),
    datasets: [
      // Indigo line
      {
        data: activeUserData && activeUserData.map(data => data.users),
        fill: true,
        backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.08)`,
        borderColor: tailwindConfig().theme.colors.indigo[500],
        borderWidth: 2,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
        clip: 20,
      },
    ],
  };

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100 flex items-center">
        <h2 className="font-semibold text-gray-800">Active Users</h2>
        <Tooltip className="ml-2">
          <div className="text-xs text-center whitespace-nowrap">Users Active Last 30 Seconds</div>
        </Tooltip>
      </header>
      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}
      <RealtimeChart data={chartData} width={595} height={248} />
    </div>
  );
}

export default DashboardCard05;
