const { SET_SESSIONS, SET_ACTIVE_SESSIONS, SET_SESSION_ANALYTICS } = require("../actions/actions_sessions");

const initialState = {
    allSessions : [],
    activeSessions : [],
    sessionAnalytics : false,
    totalSessions : false
}

const reducer_sessions = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_SESSIONS:
            var newState = JSON.parse(JSON.stringify(state))
            newState.allSessions = payload.sessions;
            newState.totalSessions = payload.totalSessions;
            return newState
        case SET_SESSION_ANALYTICS:
            var newState = JSON.parse(JSON.stringify(state))
            newState.sessionAnalytics = payload;
            return newState
        case SET_ACTIVE_SESSIONS:
            var newState = JSON.parse(JSON.stringify(state))
            newState.activeSessions = payload;
            return newState
        default:
            return state
    }
}
export default reducer_sessions;
