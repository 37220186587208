import React from 'react';
import { Link } from 'react-router-dom';
import LineChart from '../../charts/LineChart01';
import Icon from '../../images/icon-01.svg';
import EditMenu from '../../components/DropdownEditMenu';

// Import utilities
import { tailwindConfig, hexToRGB } from '../../utils/Utils';

function DashboardCard01({ summary }) {

  if(summary.length === 0) {
    return false;
  }

  var times = Array(25).fill(0);
  var yesterdaysViewTotals = Array(25).fill(0);
  var todaysViewTotals = Array(25).fill(0);
  summary.forEach(summaryData => {
    summaryData.statsPerPeriod.forEach((hourData, i) => {
      if(times[i] === 0) {
        times[i] = hourData.startTime;
      }
      yesterdaysViewTotals[i] += summaryData.statsPerPriorPeriod[i].sessionNumber;
      todaysViewTotals[i] += hourData.sessionNumber
    })
  })
  var todaysTotalNumber = todaysViewTotals.reduce((a, b) => a + b, 0)
  var yesterdaysTotalNumber = yesterdaysViewTotals.reduce((a, b) => a + b, 0)

  var totalSessions = summary.reduce((a, b) => a + b.totalSessions, 0);

  const chartData = {
    labels: times,
    datasets: [
      // Indigo line
      {
        data: todaysViewTotals,
        fill: true,
        backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.08)`,
        borderColor: tailwindConfig().theme.colors.indigo[500],
        borderWidth: 2,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
        clip: 20,
      },
      // Gray line
      {
        data: yesterdaysViewTotals,
        borderColor: tailwindConfig().theme.colors.gray[300],
        borderWidth: 2,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: tailwindConfig().theme.colors.gray[300],
        clip: 20,
      },
    ],
  };
  return (
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200">
      <div className="px-5 pt-5">
        <header className="flex justify-between items-start mb-2">
          {/* Icon */}
          <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 flex-shrink-0 text-gray-400 mr-2 text-indigo-400" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#4f46e5" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M4 8v-2a2 2 0 0 1 2 -2h2" />
            <path d="M4 16v2a2 2 0 0 0 2 2h2" />
            <path d="M16 4h2a2 2 0 0 1 2 2v2" />
            <path d="M16 20h2a2 2 0 0 0 2 -2v-2" />
            <line x1="12" y1="11" x2="12" y2="11.01" />
            <path d="M12 18l-3.5 -5a4 4 0 1 1 7 0l-3.5 5" />
          </svg>
          {/* Menu button */}
          {/* <EditMenu align="right" className="relative inline-flex">
            <li>
              <Link className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3" to="#0">Option 1</Link>
            </li>
            <li>
              <Link className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3" to="#0">Option 2</Link>
            </li>
            <li>
              <Link className="font-medium text-sm text-red-500 hover:text-red-600 flex py-1 px-3" to="#0">Remove</Link>
            </li>
          </EditMenu> */}
        </header>
        <h2 className="text-lg font-semibold text-gray-800 mb-2">Views</h2>
        <div className="text-xs font-semibold text-gray-400 uppercase mb-1">Total</div>
        <div className="flex items-start">
          <div className="text-3xl font-bold text-gray-800 mr-2">{totalSessions}</div>
          {yesterdaysTotalNumber!==0 ?
            <div className={`text-sm font-semibold text-white px-1.5 ${ todaysTotalNumber > yesterdaysTotalNumber ? 'bg-green-500' : 'bg-yellow-500'} rounded-full`}>
              {todaysTotalNumber > yesterdaysTotalNumber ? '+' : '-'} {parseInt((todaysTotalNumber/yesterdaysTotalNumber)*100)}%
            </div>
          : false}
        </div>
      </div>
      {/* Chart built with Chart.js 3 */}
      <div className="flex-grow">
        {/* Change the height attribute to adjust the chart height */}
        <LineChart data={chartData} width={389} height={128} />
      </div>
    </div>
  );
}

export default DashboardCard01;
