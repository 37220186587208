const { SET_DATE_RANGE } = require("../actions/actions_date");

const initialState = {
    startTime : '',
    endTime : '',
}

const reducer_date = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_DATE_RANGE:
            var newState = JSON.parse(JSON.stringify(state))
            newState.startTime = payload.startTime;
            newState.endTime = payload.endTime;
            return newState
        default:
            return state
    }
}
export default reducer_date;
