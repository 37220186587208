import React from 'react';

import { formatMS } from '../../utils/Format';

function AnalyticsCard13({ analytics }) {
  return (
    <div className="col-span-full">
      <div className="col-span-full">
        <header className="px-5 py-4 border-b border-gray-100">
          <h2 className="font-semibold text-gray-800">Quick Stats</h2>
        </header>
      </div>
      <div className="col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
        <div className="p-3">
          <header className="px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Clicks</h2>
            <div style={{position : 'absolute', marginLeft: '10%', marginTop:'-25px'}}>
              Avg Per Session : <strong>{analytics.clickStats.avgClicks.toFixed(2)}</strong>
            </div>
          </header>
        </div>
      </div>
      <div className="col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
        <div className="p-3">
          <header className="px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Map Moves</h2>
            <div style={{position : 'absolute', marginLeft: '10%', marginTop:'-25px'}}>
              Avg Pans : <strong>{analytics.moveStats.avgPans.toFixed(2)}</strong>
            </div>
            <div style={{position : 'absolute', marginLeft: '30%', marginTop:'-25px'}}>
              Avg Zooms : <strong>{analytics.moveStats.avgZooms.toFixed(2)}</strong>
            </div>
            <div style={{position : 'absolute', marginLeft: '50%', marginTop:'-25px'}}>
              Avg Tilts : <strong>{analytics.moveStats.avgTilts.toFixed(2)}</strong>
            </div>
            <div style={{position : 'absolute', marginLeft: '70%', marginTop:'-25px'}}>
              Avg Rotations : <strong>{analytics.moveStats.avgRotations.toFixed(2)}</strong>
            </div>

            <div style={{position : 'absolute', marginLeft: '10%', marginTop:'0px'}}>
              Total Pans : <strong>{analytics.moveStats.totalPans.toFixed(2)}</strong>
            </div>
            <div style={{position : 'absolute', marginLeft: '30%', marginTop:'0px'}}>
              Total Zooms : <strong>{analytics.moveStats.totalZooms.toFixed(2)}</strong>
            </div>
            <div style={{position : 'absolute', marginLeft: '50%', marginTop:'0px'}}>
              Total Tilts : <strong>{analytics.moveStats.totalTilts.toFixed(2)}</strong>
            </div>
            <div style={{position : 'absolute', marginLeft: '70%', marginTop:'0px'}}>
              Total Rotations : <strong>{analytics.moveStats.totalRotations.toFixed(2)}</strong>
            </div>
          </header>
        </div>
      </div>
      <div className="col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
        <div className="p-3">
          <header className="px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Mousemoves</h2>
            <div style={{position : 'absolute', marginLeft: '10%', marginTop:'-25px'}}>
              Avg Time Mousing By Session : <strong>{formatMS(analytics.mousemoveStats.avgTimeMousingPerSession)}</strong>
            </div>
            <div style={{position : 'absolute', marginLeft: '50%', marginTop:'-25px'}}>
              Avg Time Mousing By User : <strong>{formatMS(analytics.mousemoveStats.avgTimeMousingPerUser)}</strong>
            </div>
          </header>
        </div>
      </div>
      <div className="col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
        <div className="p-3">
          <header className="px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Popups</h2>
            <div style={{position : 'absolute', marginLeft: '10%', marginTop:'-25px'}}>
              Avg Popups By User : <strong>{analytics.popupStats.avgPopupsPerUser.toFixed(2)}</strong>
            </div>
            <div style={{position : 'absolute', marginLeft: '30%', marginTop:'-25px'}}>
              Total Popups Opened : <strong>{analytics.popupStats.popupsOpened.toFixed(2)}</strong>
            </div>
          </header>
        </div>
      </div>
      <div className="col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
        <div className="p-3">
          <header className="px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Layers</h2>
            <div style={{position : 'absolute', marginLeft: '10%', marginTop:'-25px'}}>
              Total Layer Interactions : <strong>{analytics.layerStats.count}</strong>
            </div>
            <div style={{position : 'absolute', marginLeft: '30%', marginTop:'-25px'}}>
              Avg Layer Interactions Per User : <strong>{analytics.layerStats.avgLayersPerUser.toFixed(2)}</strong>
            </div>
          </header>
        </div>
      </div>
      <div className="col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
        <div className="p-3">
          <header className="px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Renders</h2>
            <div style={{position : 'absolute', marginLeft: '10%', marginTop:'-25px'}}>
              Avg Tile Render Time : <strong>{formatMS(analytics.renderStats.averageRenderTime)}</strong>
            </div>
          </header>
        </div>
      </div>
      <div className="col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
        <div className="p-3">
          <header className="px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">User Times</h2>
            <div style={{position : 'absolute', marginLeft: '10%', marginTop:'-25px'}}>
              Avg User Time : <strong>{formatMS(analytics.userTimes.avgUserTime)}</strong>
            </div>
          </header>
        </div>
      </div>
    </div>
  );
}

export default AnalyticsCard13;
