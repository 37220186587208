const API_URL = process.env.REACT_APP_URL;
export const SET_SUMMARY = 'SET_SUMMARY';
export const SET_ANALYTICS = 'SET_ANALYTICS';
export const SET_RECENT_ACTIVITY = 'SET_RECENT_ACTIVITY';
export const SET_CLICK_DATA = 'SET_CLICK_DATA';
export const SET_BOUNDS_DATA = 'SET_BOUNDS_DATA';

export function getSummary(account_id, startTime, endTime) {
    return (dispatch) => {
        // ?startTime=2021-09-05&endTime=2021-09-12
        return fetch(`${API_URL}/api/dashboard/summary/${account_id}?startTime=${startTime}&endTime=${endTime}`)
        .then(resp => resp.json()).then(response => {
          console.log(response.summary)
            dispatch(setSummary(response.summary))
        })
        .catch((error) => console.log("SUMMARY ERROR: ", error));
    };
}

export function getRecentActivity(map_id, account_id, startTime, endTime) {
    return (dispatch) => {
        // ?startTime=2021-09-05&endTime=2021-09-12
        var mapIDQuery = map_id ? '?map_id='+map_id : '';
        var qs = map_id ? `?map_id=${map_id}&startTime=${startTime}&endTime=${endTime}` : `?startTime=${startTime}&endTime=${endTime}`
        return fetch(`${API_URL}/api/dashboard/latest-activity/${account_id}${qs}`)
        .then(resp => resp.json()).then(response => {
            dispatch(setRecentActivity(response.recentActivity))
        })
        .catch((error) => console.log("SUMMARY ERROR: ", error));
    };
}

export function getBoundsData(map_id, account_id, bounds, startTime, endTime) {
    return (dispatch) => {
        // ?startTime=2021-09-05&endTime=2021-09-12
        var mapIDQuery = map_id ? '/'+map_id : '';
        return fetch(`${API_URL}/api/dashboard/bounds/${account_id}${mapIDQuery}?bounds=${bounds.join()}&startTime=${startTime}&endTime=${endTime}`)
        .then(resp => resp.json()).then(response => {
            dispatch(setBoundsData(response))
        })
        .catch((error) => console.log("SUMMARY ERROR: ", error));
    };
}

export function getClickData(map_id, account_id, bounds, startTime, endTime) {
    return (dispatch) => {
        // ?startTime=2021-09-05&endTime=2021-09-12
        var mapIDQuery = map_id ? '/'+map_id : '';
        return fetch(`${API_URL}/api/dashboard/coordinates/${account_id}${mapIDQuery}?bounds=${bounds.join()}&startTime=${startTime}&endTime=${endTime}`)
        .then(resp => resp.json()).then(response => {
            dispatch(setClickData(response))
        })
        .catch((error) => console.log("SUMMARY ERROR: ", error));
    };
}

export function getAnalytics(map_id, account_id, startTime, endTime) {
    return (dispatch) => {
        // ?startTime=2021-09-05&endTime=2021-09-12
        return fetch(`${API_URL}/api/dashboard/analytics/${map_id}/${account_id}?startTime=${startTime}&endTime=${endTime}`)
        .then(resp => resp.json()).then(response => {
            dispatch(setAnalytics(response.analytics))
        })
        .catch((error) => console.log("SUMMARY ERROR: ", error));
    };
}

export function setSummary(payload){
    return {
        type: SET_SUMMARY,
        payload: payload
    };
}

export function setBoundsData(payload){
    return {
        type: SET_BOUNDS_DATA,
        payload: payload
    };
}

export function setClickData(payload){
    return {
        type: SET_CLICK_DATA,
        payload: payload
    };
}

export function setRecentActivity(payload){
    return {
        type: SET_RECENT_ACTIVITY,
        payload: payload
    };
}

export function setAnalytics(payload){
    return {
        type: SET_ANALYTICS,
        payload: payload
    };
}
