import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import Select from '../partials/actions/Select';
import Datepicker from '../components/Datepicker';
import AnalyticsMap from '../partials/analytics/AnalyticsMap';
import AnalyticsCard01 from '../partials/analytics/AnalyticsCard01';
import AnalyticsCard02 from '../partials/analytics/AnalyticsCard02';
import AnalyticsCard03 from '../partials/analytics/AnalyticsCard03';
import AnalyticsCard04 from '../partials/analytics/AnalyticsCard04';
import AnalyticsCard05 from '../partials/analytics/AnalyticsCard05';
import AnalyticsCard06 from '../partials/analytics/AnalyticsCard06';
import AnalyticsCard07 from '../partials/analytics/AnalyticsCard07';
import AnalyticsCard08 from '../partials/analytics/AnalyticsCard08';
import AnalyticsCard09 from '../partials/analytics/AnalyticsCard09';
import AnalyticsCard10 from '../partials/analytics/AnalyticsCard10';
import AnalyticsCard11 from '../partials/analytics/AnalyticsCard11';
import AnalyticsCard12 from '../partials/analytics/AnalyticsCard12';
import AnalyticsCard13 from '../partials/analytics/AnalyticsCard13';

import { getMaps } from '../actions/actions_maps'
import { getActiveUsers } from '../actions/actions_users'
import { getAnalytics, getRecentActivity, getClickData, getBoundsData } from '../actions/actions_dashboard'

function Analytics({ dispatch, allMaps, clickData, boundsData, analytics, recentActivity, activeUsers, startTime, endTime, currentAccount }) {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedMap, setSelectedMap] = useState(false)
  const [currentTime, setCurrentTime] = useState(new Date().getTime())
  const [currentInterval, setCurrentInterval] = useState(false)

  useEffect(() => {
    if(currentAccount) {
      dispatch(getMaps(currentAccount.id))
      let searchParams = new URLSearchParams(window.location.search)
      if(searchParams.has('map_id')) {
        setSelectedMap(searchParams.get('map_id'))
      }
    }
  }, [currentAccount])

  useEffect(() => {
    if(currentAccount && selectedMap && allMaps.length > 0) {
      var url = new URL(window.location);
      let searchParams = new URLSearchParams(window.location.search)
      searchParams.set('map_id', selectedMap)
      window.history.replaceState(null, null, "?"+searchParams.toString());

      doFetch();

      if(currentInterval) {
        clearInterval(currentInterval);
      }
      var thisInterval = setInterval(() => {
        setCurrentTime(new Date().getTime());
      }, 10000);
      setCurrentInterval(thisInterval)
    }
  }, [currentAccount, allMaps, selectedMap])

  useEffect(() => {
    if(currentAccount && selectedMap && allMaps.length > 0) {
      var thisMap = allMaps.find(map => map.map_id === selectedMap)
      dispatch(getAnalytics(thisMap.map_id, currentAccount.id, startTime, endTime))
    }
  }, [currentAccount, startTime, endTime])

  useEffect(() => {
    if(currentAccount && selectedMap) {
      var thisMap = allMaps.find(map => map.map_id === selectedMap)
      // dispatch(getRecentActivity(thisMap.map_id, currentAccount.id, startTime, endTime))
      dispatch(getActiveUsers(thisMap.map_id, currentAccount.id))
    }
  }, [currentAccount, currentTime])

  const doFetch = () => {
    var thisMap = allMaps.find(map => map.map_id === selectedMap)
    dispatch(getRecentActivity(thisMap.map_id, currentAccount.id, startTime, endTime))
    dispatch(getAnalytics(thisMap.map_id, currentAccount.id, startTime, endTime));
    dispatch(getActiveUsers(thisMap.map_id, currentAccount.id))
  }

  const getMapData = (bounds, type) => {
    var thisMap = allMaps.find(map => map.map_id === selectedMap)
    console.log(bounds, type, thisMap)
    if(type === 'clicks') {
      dispatch(getClickData(thisMap.map_id, currentAccount.id, bounds, startTime, endTime))
    }
    if(type === 'bounds') {
      dispatch(getBoundsData(thisMap.map_id, currentAccount.id, bounds, startTime, endTime))
    }
  }

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">

              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">Map Analytics</h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">

                <div className="relative">
                  <div onClick={() => doFetch()} className="form-input text-gray-500 hover:text-gray-600 font-medium focus:border-gray-300 w-10 h-10" style={{cursor: "pointer"}}>
                    <div className="left-auto items-center" style={{marginTop: 3}}>
                      <svg className="w-4 h-4 fill-current text-gray-500" viewBox="0 0 24 24">
                        <path d="M13.5 2c-5.621 0-10.211 4.443-10.475 10h-3.025l5 6.625 5-6.625h-2.975c.257-3.351 3.06-6 6.475-6 3.584 0 6.5 2.916 6.5 6.5s-2.916 6.5-6.5 6.5c-1.863 0-3.542-.793-4.728-2.053l-2.427 3.216c1.877 1.754 4.389 2.837 7.155 2.837 5.79 0 10.5-4.71 10.5-10.5s-4.71-10.5-10.5-10.5z"/>
                      </svg>
                    </div>
                  </div>
                </div>
                <Select selectedMap={selectedMap} setSelectedMap={setSelectedMap} options={allMaps.map(map => { return { id : map.id, map_id : map.map_id, name : map.map_name } })} />
                {/* Datepicker built with flatpickr */}
                <Datepicker align="right" />

              </div>

            </div>

            {/* Cards */}
            {allMaps.length === 0 ? <p>Please set up a map in the Maps section.</p> : false}
            {analytics ?
              <div className="grid grid-cols-12 gap-6">

                {/* Map view of analytics */}
                <AnalyticsMap getMapData={getMapData} selectedMap={selectedMap} analytics={analytics} clickData={clickData} boundsData={boundsData} />
                {/* Line chart (Analytics) */}
                <AnalyticsCard01 analytics={analytics} />
                {/*  Line chart (Active Users Right Now) */}
                <AnalyticsCard02 activeUsers={activeUsers} />
                {/* Stacked bar chart (Behaviour - Loading, Moving, Mousing, Clicking) */}
                <AnalyticsCard03 analytics={analytics} />
                {/* Horizontal bar chart (Destop vs Mobile sessions) */}
                <AnalyticsCard04 analytics={analytics} />
                {/* Report card (Top User Locations) */}
                <AnalyticsCard05 analytics={analytics} />
                {/* Report card (Top Popups) */}
                <AnalyticsCard06 analytics={analytics} />
                {/* Report card (Top User Times) */}
                <AnalyticsCard07 analytics={analytics} />
                {/* Doughnut chart (Sessions By Platform) */}
                <AnalyticsCard08 analytics={analytics} />
                {/* Doughnut chart (Visit By Location) */}
                <AnalyticsCard09 analytics={analytics} />
                {/* Polar chart (Sessions By Time Spent) */}
                <AnalyticsCard10 analytics={analytics} />
                {/* Table (Top Layer Interactions) */}
                <AnalyticsCard11 analytics={analytics} />
                {/* Table (Recent Behaviour) */}
                <AnalyticsCard12 recentActivity={recentActivity} />
                {/* List of additional summary stats, just plain table of totals */}
                <AnalyticsCard13 analytics={analytics} />

              </div>
            : false}

          </div>
        </main>

      </div>

    </div>
  );
}

const analyticsState = (state)=>({
  allMaps: state.maps.allMaps,
  analytics : state.dashboard.analytics,
  activeUsers : state.users.activeUsers,
  startTime: state.date.startTime,
  endTime: state.date.endTime,
  clickData : state.dashboard.clickData,
  boundsData : state.dashboard.boundsData,
  recentActivity : state.dashboard.recentActivity,
  currentAccount : state.account.currentAccount
})

export default connect(analyticsState)(Analytics)
