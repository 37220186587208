import React from 'react';
import { Link } from 'react-router-dom';

function AnalyticsCard06({ userAnalytics }) {

  const viewPopupHTML = (divElement) => {
    var containerElement = document.getElementById('popup-element');
    containerElement.replaceChildren()
    divElement.style.backgroundColor = '#FFF';
    divElement.childNodes[0].style.transform = 'none'
    containerElement.append(divElement);
    containerElement.style.display = 'block';
  }

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">Top Popups</h2>
      </header>
      <div className="flex-grow p-3">
        <div className="flex flex-col h-full">
          {/* Card content */}
          <div className="flex-grow">
            <ul className="flex justify-between text-xs uppercase text-gray-400 font-semibold px-2 space-x-2">
              <li>Content</li>
              <li>Views</li>
            </ul>

            <div id="popup-element" style={{display: 'none', border: 'thin #333 dashed', boxShadow: '2px 2px 10px #ccc', padding: 10}}></div>

            <ul className="space-y-1 text-sm text-gray-800 mt-3 mb-4">
              {userAnalytics.stats.popupStats.topPopups.map(popup => {
                var popupFakeDiv = document.createElement('div');
                popupFakeDiv.innerHTML = popup.html;
                return (
                  <li className="relative px-2 py-1">
                    <div className="absolute inset-0 bg-green-100" aria-hidden="true" style={{width: ((popup.count / userAnalytics.stats.popupStats.popupsOpened) * 100) + '%'}}></div>
                    <div className="relative flex justify-between space-x-2">
                      <div>{popupFakeDiv.textContent} <br /><span onClick={() => viewPopupHTML(popupFakeDiv)} className="text-light-blue-500">Click to see full HTML</span></div>
                      <div className="font-medium">{popup.count}</div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
          {/* Card footer */}
          {/* <div className="text-center pt-4 pb-1 border-t border-gray-100">
            <Link className="text-sm font-medium text-indigo-500 hover:text-indigo-600" to="#0">Page Report -&gt;</Link>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default AnalyticsCard06;
