import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import AuthImage from '../images/auth-image.jpg';
import AuthDecoration from '../images/auth-decoration.png';

import { connect } from 'react-redux';
import { resetPassword, setPassword } from '../actions/actions_account';

function ResetPassword({ dispatch, message }) {

  const [token, setToken] = useState(false);

  useEffect(() => {
    const passwordResetToken = new URLSearchParams(window.location.search).get("token");
    if(passwordResetToken) {
      setToken(passwordResetToken);
    }
  }, [])

  const doReset = (e) =>{
    e.preventDefault()
    if(token) {
      const password = e.target['0'].value
      dispatch(setPassword(password, token))
    } else {
      const email = e.target['0'].value
      dispatch(resetPassword(email))
    }
  }

  return (
    <main className="bg-white">

      <div className="relative md:flex">

        {/* Content */}
        <div className="md:w-1/2">
          <div className="max-w-sm mx-auto min-h-screen flex flex-col justify-center px-4 py-8">

            <div className="w-full">
              <h1 className="text-3xl text-gray-800 font-bold mb-6">Reset your Password ✨</h1>
              {/* Form */}
              <form onSubmit={doReset}>
                <div className="space-y-4">
                  {token ?
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="text">New Password <span className="text-red-500">*</span></label>
                      <input id="password" className="form-input w-full" type="password" />
                    </div>
                  :
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="email">Email Address <span className="text-red-500">*</span></label>
                      <input id="email" className="form-input w-full" type="email" />
                    </div>
                  }
                </div>
                <div className="flex justify-end mt-6">
                  <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white whitespace-nowrap">{token ? "Set New Password" : "Send Reset Link"}</button>
                </div>
                {message ?
                  <div className="mt-5">
                    <div className="bg-yellow-100 text-yellow-600 px-3 py-2 rounded">
                      <span className="text-sm">
                        {message}
                      </span>
                    </div>
                  </div>
                : false}
              </form>
            </div>
            {/* Footer */}
            <div className="pt-5 mt-6 border-t border-gray-200">
              <div className="text-sm">
                <Link className="font-medium text-indigo-500 hover:text-indigo-600" to="/">Sign In</Link>
              </div>
            </div>

          </div>
        </div>

        {/* Image */}
        <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
          <img className="object-cover object-center w-full h-full" src={AuthImage} width="760" height="1024" alt="Authentication" />
          <img className="absolute top-1/4 left-0 transform -translate-x-1/2 ml-8" src={AuthDecoration} width="218" height="224" alt="Authentication decoration" />
        </div>

      </div>

    </main>
  );
}

const accountState = (state) => ({
  message : state.account.message
})

export default connect(accountState)(ResetPassword)
