import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import AuthImage from '../images/chart-map.jpg';
import AuthDecoration from '../images/logo-big-mapster.png';

import { connect } from 'react-redux';
import { signup } from '../actions/actions_account';

function Signup({ dispatch, message }) {

  const [loading, setLoading] = useState(false);

  const doSignup = (e) => {
    e.preventDefault()
    const account_name = e.target['0'].value
    const email = e.target['1'].value
    const password = e.target['2'].value
    // const email = e.target.AuthDecoration
    setLoading(true);
    dispatch(signup(email, password, account_name))
  }

  useEffect(() => {
    if(message) {
      setLoading(false);
    }
  }, [message]);

  return (
    <main className="bg-white">

      <div className="relative md:flex">

        {/* Content */}
        <div className="md:w-1/2">
          <div className="max-w-sm mx-auto min-h-screen flex flex-col justify-center px-4 py-8">

            <div className="w-full">
              <h1 className="text-3xl text-gray-800 font-bold mb-6">Create your account</h1>
              {/* Form */}
              <form onSubmit={doSignup}>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="name">Account Name <span className="text-red-500">*</span></label>
                    <input id="name" className="form-input w-full" type="text" />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="email">Email Address <span className="text-red-500">*</span></label>
                    <input id="email" className="form-input w-full" type="email" />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="password">Password</label>
                    <input id="password" className="form-input w-full" type="password" autoComplete="on" />
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6">
                  <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white whitespace-nowrap" to="/">
                    Sign Up
                    {loading ? <svg style={{marginLeft:'10px'}} width="16" height="16" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
                        <g fill="none" fill-rule="evenodd">
                            <g transform="translate(1 1)" stroke-width="2">
                                <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
                                <path d="M36 18c0-9.94-8.06-18-18-18">
                                    <animateTransform
                                        attributeName="transform"
                                        type="rotate"
                                        from="0 18 18"
                                        to="360 18 18"
                                        dur="1s"
                                        repeatCount="indefinite"/>
                                </path>
                            </g>
                        </g>
                    </svg> : false}
                  </button>
                </div>
                {message ?
                  <div className="mt-5">
                    <div className="bg-yellow-100 text-yellow-600 px-3 py-2 rounded">
                      <svg className="inline w-3 h-3 flex-shrink-0 fill-current mr-2" viewBox="0 0 12 12">
                        <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                      </svg>
                      <span className="text-sm">
                        {message}
                      </span>
                    </div>
                  </div>
                : false}
              </form>
              {/* Footer */}
              <div className="pt-5 mt-6 border-t border-gray-200">
                <div className="text-sm">
                  Have an account? <Link className="font-medium text-indigo-500 hover:text-indigo-600" to="/">Sign In</Link>
                </div>
              </div>
            </div>

          </div>
        </div>

        {/* Image */}
        <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
          <img className="object-cover object-center w-full h-full" src={AuthImage} width="760" height="1024" alt="Authentication" />
          <img className="absolute top-1/4 left-0 transform -translate-x-1/2 ml-8" src={AuthDecoration} width="218" height="224" alt="Authentication decoration" />
        </div>

      </div>

    </main>
  );
}

const userState = (state)=>({
  token: state.account.token,
  message : state.account.message
})

export default connect(userState)(Signup)
