import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import AuthImage from '../images/chart-map.jpg';
import AuthDecoration from '../images/logo-big-mapster.png';

import { connect } from 'react-redux';
import { login, emailConfirm, clearMsg } from '../actions/actions_account';

function Signin({dispatch, token, message}) {
  const history = useHistory()

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const emailConfirmToken = new URLSearchParams(window.location.search).get("token");
    if(emailConfirmToken) {
      dispatch(emailConfirm(emailConfirmToken));
    }
  }, [])

  useEffect(() => {
    if(token) {
      // User signed in successfully
      history.push('/dashboard');
    }
  }, [token])

  useEffect(() => {
    if(message) {
      setLoading(false);
    }
  }, [message]);

  const doLogin = (e) =>{
    e.preventDefault()
    const email = e.target['0'].value
    const password = e.target['1'].value
    setLoading(true)
    dispatch(login(email, password))
  }

  const removeError = () =>{
    dispatch(clearMsg())
  }

  return (
    <main className="bg-white">

      <div className="relative md:flex">

        {/* Content */}
        <div className="md:w-1/2">
          <div className="max-w-sm mx-auto min-h-screen flex flex-col justify-center px-4 py-8">

            <div className="w-full">
              <h1 className="text-3xl text-gray-800 font-bold mb-6">Welcome to the Mapster Analytics Dashboard!</h1>
              {/* Form */}
              <form onSubmit={doLogin}>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="email">Email Address</label>
                    <input id="email" className="form-input w-full" onChange={removeError} type="email" />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="password">Password</label>
                    <input id="password" className="form-input w-full"  onChange={removeError}  type="password" autoComplete="on" />
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6">
                  <div className="mr-1">
                    <Link className="text-sm underline hover:no-underline" to="/reset-password">Forgot Password?</Link>
                  </div>
                  <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3">
                    Sign In
                    {loading ? <svg style={{marginLeft:'10px'}} width="16" height="16" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
                        <g fill="none" fill-rule="evenodd">
                            <g transform="translate(1 1)" stroke-width="2">
                                <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
                                <path d="M36 18c0-9.94-8.06-18-18-18">
                                    <animateTransform
                                        attributeName="transform"
                                        type="rotate"
                                        from="0 18 18"
                                        to="360 18 18"
                                        dur="1s"
                                        repeatCount="indefinite"/>
                                </path>
                            </g>
                        </g>
                    </svg> : false}
                  </button>
                </div>
              </form>
              {message ?
                <div className="mt-5">
                  <div className="bg-yellow-100 text-yellow-600 px-3 py-2 rounded">
                    <span className="text-sm">
                      {message}
                    </span>
                  </div>
                </div>
              : false}
              {/* Footer */}
              <div className="pt-5 mt-6 border-t border-gray-200">
                <div className="text-sm">
                  Don’t you have an account? <Link className="font-medium text-indigo-500 hover:text-indigo-600" to="/signup">Sign Up</Link>
                </div>
              </div>
            </div>

          </div>
        </div>

        {/* Image */}
        <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
          <img className="object-cover object-right w-full h-full" src={AuthImage} width="760" height="1024" alt="Authentication" />
          <img className="absolute top-1/4 left-0 transform -translate-x-1/2 ml-8" src={AuthDecoration} width="218" height="224" alt="Authentication decoration" />
        </div>

      </div>

    </main>
  );
}

const accountState = (state) => ({
  token: state.account.token,
  message : state.account.message
})

export default connect(accountState)(Signin)
