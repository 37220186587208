import React, { useState, useEffect } from 'react';
import { focusHandling } from 'cruip-js-toolkit';
import Sessions from './SessionsTableItem';

function InvoicesTable({
  allSessions
}) {

  const [list, setList] = useState([]);

  useEffect(() => {
    setList(allSessions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSessions]);

  useEffect(() => {
    focusHandling('outline');
  }, [list]);


  return (
    <div className="bg-white shadow-lg rounded-sm border border-gray-200 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-gray-800">Sessions <span className="text-gray-400 font-medium">{list.length}</span></h2>
      </header>
      <div>

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-t border-b border-gray-200">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Map</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Session ID</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">User ID</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Platform</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">OS</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Browser</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Location</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Active</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-200">
              {
                list.map(session => {
                  return (
                    <Sessions
                      key={session.session_id}
                      map_name={session.map_name}
                      map_id={session.map_id}
                      session_id={session.session_id}
                      user_id={session.user_id}
                      platform={session.platform}
                      os={session.os}
                      browser={session.browser}
                      location={session.location}
                      timeStart={session.timeStart}
                      lastActive={session.lastActive}
                    />
                  )
                })
              }
            </tbody>
          </table>

        </div>
      </div>
    </div>
  );
}

export default InvoicesTable;
