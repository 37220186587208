import React from 'react';
import { NavLink } from 'react-router-dom';
import LineChart from '../../charts/LineChart03';

// Import utilities
import { tailwindConfig, hexToRGB } from '../../utils/Utils';

function AnalyticsCard01({ sessionAnalytics }) {

  return (
    <div className="col-span-6 sm:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 style={{display: 'inline-block'}} className="font-semibold text-gray-800">User Information</h2>
        <div style={{display: 'inline-block', marginLeft: '10px'}} className="font-light text-light-blue-500"><NavLink to={`/users/single?user_id=${sessionAnalytics.data.loadData.user_id}`}>See More -&gt;</NavLink></div>
      </header>
      <div className="p-3">

        {/* Table */}
        <div className="overflow-x-auto" style={{maxHeight: 400, overflowY : 'scroll'}}>
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Type</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Value</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-100">
              <tr>
                <td className="p-2 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="font-medium text-gray-800">OS</div>
                  </div>
                </td>
                <td className="p-2 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="font-medium text-gray-800">{sessionAnalytics.stats.loadStats.os}</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="p-2 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="font-medium text-gray-800">Location</div>
                  </div>
                </td>
                <td className="p-2 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="font-medium text-gray-800">{sessionAnalytics.stats.loadStats.ipdata.city}</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="p-2 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="font-medium text-gray-800">Platform</div>
                  </div>
                </td>
                <td className="p-2 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="font-medium text-gray-800">{sessionAnalytics.stats.loadStats.platform}</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="p-2 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="font-medium text-gray-800">URL</div>
                  </div>
                </td>
                <td className="p-2 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="font-medium text-gray-800">{sessionAnalytics.stats.loadStats.url}</div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  );
}

export default AnalyticsCard01;
