import React, { useState } from 'react';
import { connect } from 'react-redux';

import { createMap } from '../../actions/actions_maps';

import Image from '../../images/user-avatar-80.png';

function AddMapsPanel({ dispatch, currentAccount, allMaps }) {

  const [sync, setSync] = useState(false);

  const checkMap = () => {
    var name = document.getElementById('map-name').value;
    var url = document.getElementById('website-url').value;
    if(!name || !url) {
      alert('Please fill in name and URL.');
    } else {
      dispatch(createMap(name, url, currentAccount.id))
    }
  }

  return (
    <div className="flex-grow">
      {/* Panel body */}
      <div className="p-6 space-y-6">
        <h2 className="text-2xl text-gray-800 font-bold mb-5">Add Map</h2>
        {/* Business Profile */}
        <section>
          <div className="text-sm">With your current account, you can create unlimited maps for tracking.</div>
          <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="map-name">Map Name</label>
              <input id="map-name" className="form-input w-full" type="text" />
            </div>
            <div className="sm:w-1/3">
              <label className="block text-sm font-medium mb-1" htmlFor="website-url">Website URL</label>
              <input id="website-url" className="form-input w-full" type="text" />
            </div>
          </div>
        </section>
      </div>
      {/* Panel footer */}
      <footer>
        <div className="flex flex-col px-6 py-5 border-t border-gray-200">
          <div className="flex self-end">
            <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3" onClick={() => checkMap()}>Add Map</button>
          </div>
          <div className="flex self-end">
            <p style={{fontSize: 12,marginTop: 10}}>Total Current Maps: {allMaps.length}</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

const mapsState = (state)=>({
  allMaps: state.maps.allMaps,
  currentAccount : state.account.currentAccount
})

export default connect(mapsState)(AddMapsPanel)
