import React from 'react';
import BarChart from '../../charts/BarChart03';

// Import utilities
import { tailwindConfig } from '../../utils/Utils';

function AnalyticsCard03({ analytics }) {

  var startAndEndTimePeriods = [analytics.loadStats.loadStatsPerPeriod[0].startTime, analytics.loadStats.loadStatsPerPeriod[analytics.loadStats.loadStatsPerPeriod.length - 1].startTime]
  var timeDivision = (startAndEndTimePeriods[1] - startAndEndTimePeriods[0])/6

  var labels = [];
  for(var i=0; i<=5; i++) {
    labels.push(startAndEndTimePeriods[0] + (timeDivision * i))
  }
  var loadData = [0, 0, 0, 0, 0, 0];
  labels.forEach((timeLabel, i) => {
    analytics.loadStats.loadStatsPerPeriod.forEach(periodStats => {
      if(periodStats.startTime >= timeLabel && periodStats.startTime <= (timeLabel+timeDivision)) {
        loadData[i] += periodStats.sessionNumber;
      }
    })
  })
  var moveData = [0, 0, 0, 0, 0, 0];
  labels.forEach((timeLabel, i) => {
    analytics.moveStats.moveStatsPerPeriod.forEach(periodStats => {
      if(periodStats.startTime >= timeLabel && periodStats.startTime <= (timeLabel+timeDivision)) {
        moveData[i] += periodStats.moveNumber;
      }
    })
  })
  var clickData = [0, 0, 0, 0, 0, 0];
  labels.forEach((timeLabel, i) => {
    analytics.clickStats.clickStatsPerPeriod.forEach(periodStats => {
      if(periodStats.startTime >= timeLabel && periodStats.startTime <= (timeLabel+timeDivision)) {
        clickData[i] += periodStats.clickNumber;
      }
    })
  })
  var popupData = [0, 0, 0, 0, 0, 0];
  labels.forEach((timeLabel, i) => {
    analytics.popupStats.popupStatsPerPeriod.forEach(periodStats => {
      if(periodStats.startTime >= timeLabel && periodStats.startTime <= (timeLabel+timeDivision)) {
        popupData[i] += periodStats.popupNumber;
      }
    })
  })

  const chartData = {
    labels: labels,
    datasets: [
      // Stack
      {
        label: 'Loads',
        data: loadData,
        backgroundColor: tailwindConfig().theme.colors.indigo[700],
        hoverBackgroundColor: tailwindConfig().theme.colors.indigo[800],
        barPercentage: 0.66,
        categoryPercentage: 0.66,
      },
      // Stack
      {
        label: 'Moves',
        data: moveData,
        backgroundColor: tailwindConfig().theme.colors.indigo[500],
        hoverBackgroundColor: tailwindConfig().theme.colors.indigo[600],
        barPercentage: 0.66,
        categoryPercentage: 0.66,
      },
      // Stack
      {
        label: 'Clicks',
        data: clickData,
        backgroundColor: tailwindConfig().theme.colors.indigo[300],
        hoverBackgroundColor: tailwindConfig().theme.colors.indigo[400],
        barPercentage: 0.66,
        categoryPercentage: 0.66,
      },
      // Stack
      {
        label: 'Popups',
        data: popupData,
        backgroundColor: tailwindConfig().theme.colors.indigo[100],
        hoverBackgroundColor: tailwindConfig().theme.colors.indigo[200],
        barPercentage: 0.66,
        categoryPercentage: 0.66,
      },
    ],
  };

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100 flex items-center">
        <h2 className="font-semibold text-gray-800">User Behaviours</h2>
      </header>
      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}
      <BarChart data={chartData} width={595} height={248} />
    </div>
  );
}

export default AnalyticsCard03;
