import React from 'react';
import { NavLink } from 'react-router-dom';

function DashboardCard10({ recentActivity }) {
  var latestActivity = []
  // summary.forEach(summaryData => {
  //   latestActivity = latestActivity.concat(summaryData.latestActivity);
  // })
  // var fiveLatest = latestActivity.slice(0, 5);
  console.log(recentActivity)
  var fiveLatest = recentActivity

  return (
    <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">Recent Activity</h2>
      </header>
      <div className="p-3">

        {/* Card content */}
        {/* "Today" group */}
        <div>
          <ul className="my-1">
            {fiveLatest.map(activity => {
              return (
                <li className="flex px-2">
                  {activity.type === 'load' ?
                    <div className="w-9 h-9 rounded-full flex-shrink-0 bg-indigo-500 my-2 mr-3">
                      <svg xmlns="http://www.w3.org/2000/svg" style={{marginLeft:4,marginTop:4}} className="w-7 h-7 text-indigo-50" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFF" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <line x1="12" y1="6" x2="12" y2="3" />
                        <line x1="16.25" y1="7.75" x2="18.4" y2="5.6" />
                        <line x1="18" y1="12" x2="21" y2="12" />
                        <line x1="16.25" y1="16.25" x2="18.4" y2="18.4" />
                        <line x1="12" y1="18" x2="12" y2="21" />
                        <line x1="7.75" y1="16.25" x2="5.6" y2="18.4" />
                        <line x1="6" y1="12" x2="3" y2="12" />
                        <line x1="7.75" y1="7.75" x2="5.6" y2="5.6" />
                      </svg>
                    </div>
                  : false}
                  {activity.type === 'click' ?
                    <div className="w-9 h-9 rounded-full flex-shrink-0 bg-red-500 my-2 mr-3">
                      <svg xmlns="http://www.w3.org/2000/svg" style={{marginLeft:4,marginTop:4}} className="w-7 h-7 text-red-50" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFF" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <line x1="3" y1="12" x2="6" y2="12" />
                        <line x1="12" y1="3" x2="12" y2="6" />
                        <line x1="7.8" y1="7.8" x2="5.6" y2="5.6" />
                        <line x1="16.2" y1="7.8" x2="18.4" y2="5.6" />
                        <line x1="7.8" y1="16.2" x2="5.6" y2="18.4" />
                        <path d="M12 12l9 3l-4 2l-2 4l-3 -9" />
                      </svg>
                    </div>
                  : false}
                  {activity.type === 'map move' ?
                    <div className="w-9 h-9 rounded-full flex-shrink-0 bg-green-500 my-2 mr-3">
                      <svg xmlns="http://www.w3.org/2000/svg" style={{marginLeft:4,marginTop:4}} className="w-7 h-7 text-green-50" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFF" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M13 21v-13a4 4 0 1 1 4 4h-13" />
                        <path d="M7 15l-3 -3l3 -3" />
                      </svg>
                    </div>
                  : false}
                  {activity.type === 'layer' ?
                    <div className="w-9 h-9 rounded-full flex-shrink-0 bg-light-blue-500 my-2 mr-3">
                      <svg xmlns="http://www.w3.org/2000/svg" style={{marginLeft:4,marginTop:4}} className="w-7 h-7 fill-current text-light-blue-50" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFF" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M16 16v2a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h2v-2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-2" />
                        <polyline points="10 8 8 8 8 10" />
                        <polyline points="8 14 8 16 10 16" />
                        <polyline points="14 8 16 8 16 10" />
                        <polyline points="16 14 16 16 14 16" />
                      </svg>
                    </div>
                  : false}
                  <div className="flex-grow flex items-center border-b border-gray-100 text-sm py-2">
                    <div className="flex-grow flex justify-between">
                      <div className="self-center">A {activity.type} was performed by <a className="font-medium text-gray-800 hover:text-gray-900" href="#0">{activity.user}</a> at {new Date(activity.time).toLocaleTimeString()}, {new Date(activity.time).toLocaleDateString()}</div>
                      <div className="flex-shrink-0 self-end ml-2">
                        <NavLink to={`/users/single?user_id=${activity.user}`} className="font-medium text-indigo-500 hover:text-indigo-600">
                          View<span className="hidden sm:inline"> -&gt;</span>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>

      </div>
    </div>
  );
}

export default DashboardCard10;
