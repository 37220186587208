import React, { useState, useEffect } from 'react';
import maplibregl from 'maplibre-gl';
import * as turf from '@turf/turf'

function AnalyticsMap({ analytics }) {

  const [ map, setMap ] = useState(false)
  const [ loaded, setLoaded] = useState(false);
  const [ clickGeoJSON, setClickGeoJSON ] = useState(false);
  const [ boundsGeoJSON, setBoundsGeoJSON ] = useState(false);
  const [ mapBounds, setMapBounds ] = useState(false)
  const [ type, setType ] = useState(false);
  const [ maxNumberMap, setMaxNumberMap ] = useState(false);
  const [ minNumberMap, setMinNumberMap ] = useState(false);

    useEffect(() => {
      const newMap = new maplibregl.Map({
        container: 'map', // container ID
        style: {
          'version': 8,
          "glyphs": "https://fonts.openmaptiles.org/{fontstack}/{range}.pbf",
          'sources': {
            'raster-tiles': {
            'type': 'raster',
            'tiles': [
                'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'
              ],
              'tileSize': 256,
              'attribution': '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }
          },
          'layers': [
            {
            'id': 'simple-tiles',
            'type': 'raster',
            'source': 'raster-tiles',
            'minzoom': 0,
            'maxzoom': 22
            }
          ]
        }, // style URL
        center: [-100.8603, 38.2700], // starting position [lng, lat]
        zoom: 3 // starting zoom
      });
      newMap.on('load', () => {
        setMap(newMap);
        setType('bounds');
        setMapBounds(newMap.getBounds());
      })
      newMap.on('moveend', () => {
        setMapBounds(newMap.getBounds());
      })
    }, [])

    useEffect(() => {
      if(map && analytics) {
        var coordinates = analytics.stats.clickStats.coordinates.map(coord => JSON.parse(coord));
        var clickGeoJSON = { type : "FeatureCollection", features : [] }
        coordinates.forEach(coordinate => {
          clickGeoJSON.features.push({
            type : "Feature",
            properties : {},
            geometry : {
              type : "Point",
              coordinates : coordinate
            }
          })
        })
        setClickGeoJSON(clickGeoJSON)

        var boundsSet = analytics.stats.moveStats.bounds.map(bound => bound);
        var boundsGeoJSON = { type : "FeatureCollection", features : [] }
        boundsSet.forEach(bound => {
          const boundPoly = turf.bboxPolygon([bound[0][0], bound[0][1], bound[1][0], bound[1][1]])
          boundsGeoJSON.features.push(boundPoly)
        })
        setBoundsGeoJSON(boundsGeoJSON)
      }
    }, [map, analytics])

    useEffect(() => {
      if(map) {
        if(type === 'clicks') {
          createClickHeatmap(map, mapBounds, clickGeoJSON)
        }
        if(type === 'bounds') {
          createBoundsHeatmap(map, mapBounds, boundsGeoJSON)
        }
      }
    }, [type, mapBounds, clickGeoJSON, boundsGeoJSON])

    const createBoundsHeatmap = (map, mapBounds, boundsGeoJSON) => {
      if(map.getLayer('click-hex-fill')) {
        map.setLayoutProperty('click-hex-fill', 'visibility', 'none');
      }
      if(map.getLayer('click-events')) {
        map.setLayoutProperty('click-events', 'visibility', 'none');
      }

      if(map.getSource('bounds-events')) {
        map.getSource('bounds-events').setData(boundsGeoJSON)
        map.setLayoutProperty('bounds-events', 'visibility', 'visible');
        if(map.getLayer('bounds-hex-fill')) {
          map.setLayoutProperty('bounds-hex-fill', 'visibility', 'visible');
        }
      } else {
        map.addSource('bounds-events', {
          type : 'geojson',
          data : boundsGeoJSON
        })
        map.addLayer({
          id : 'bounds-events',
          source : 'bounds-events',
          type: 'line',
          paint : {
            'line-opacity' : 0.5
          }
        })
      }
      if(boundsGeoJSON.features.length > 0 && !loaded) {
        setLoaded(true);
        map.fitBounds(turf.bbox(boundsGeoJSON), { padding: 50, duration: 0 });
      }
      if(mapBounds) {
        var feature = { type : 'FeatureCollection', features : [{
          type : "Feature",
          properties : {},
          geometry : {
            type : "LineString",
            coordinates : [[mapBounds._sw.lng, mapBounds._sw.lat], [mapBounds._ne.lng, mapBounds._ne.lat]]
          }
        }]}
        var length = turf.length(feature);
        var bearing = turf.bearing(feature.features[0].geometry.coordinates[0], feature.features[0].geometry.coordinates[1]);
        var adjustedLineStart = turf.destination(feature.features[0].geometry.coordinates[0], length/10, 225);
        var adjustedLineEnd = turf.destination(feature.features[0].geometry.coordinates[1], length/10, 45);
        feature.features[0].geometry.coordinates[0] = adjustedLineStart.geometry.coordinates;
        feature.features[0].geometry.coordinates[1] = adjustedLineEnd.geometry.coordinates;
        // var bufferedFeature = turf.buffer(feature, length/10)
        var bounds = turf.bbox(feature)
        var hexGrid = turf.hexGrid(bounds, length/50);
        var maxIntersects = 0;
        var minIntersects = 999;
        hexGrid.features.forEach(hex => {
          hex.properties.count = 0;
          boundsGeoJSON.features.forEach(thisBoundPoly => {
            var intersects = turf.intersect(thisBoundPoly, hex);
            if(intersects) {
              hex.properties.count += 1;
            }
          })
          if(hex.properties.count > maxIntersects) {
            maxIntersects = hex.properties.count
          }
          if(hex.properties.count < minIntersects) {
            minIntersects = hex.properties.count
          }
        })
        setMinNumberMap(minIntersects)
        setMaxNumberMap(maxIntersects)
        if(minIntersects !== 999 && maxIntersects !== 0) {
          if(map.getSource('bounds-hex')) {
            map.getSource('bounds-hex').setData(hexGrid)
            map.setPaintProperty('bounds-hex-fill', 'fill-color', [
              'interpolate',
              ['linear'],
              ['get', 'count'],
              minIntersects,
              'blue',
              maxIntersects*0.2,
              'royalblue',
              maxIntersects*0.4,
              'cyan',
              maxIntersects*0.6,
              'lime',
              maxIntersects*0.8,
              'yellow',
              maxIntersects,
              'red'
            ])
          } else {
            map.addSource('bounds-hex', {
              type : 'geojson',
              data : hexGrid
            })
            map.addLayer({
              id : 'bounds-hex-fill',
              source : 'bounds-hex',
              type: 'fill',
              paint : {
                'fill-outline-color' : 'rgba(0,0,0,0)',
                'fill-color' : [
                  'interpolate',
                  ['linear'],
                  ['get', 'count'],
                  minIntersects,
                  'blue',
                  maxIntersects*0.2,
                  'royalblue',
                  maxIntersects*0.4,
                  'cyan',
                  maxIntersects*0.6,
                  'lime',
                  maxIntersects*0.8,
                  'yellow',
                  maxIntersects,
                  'red'
                ],
                'fill-opacity' : 0.2
              }
            })
          }
        }
      }
    }

    const createClickHeatmap = (map, mapBounds, clickGeoJSON) => {
      if(map.getLayer('bounds-hex-fill')) {
        map.setLayoutProperty('bounds-hex-fill', 'visibility', 'none');
      }
      if(map.getLayer('bounds-events')) {
        map.setLayoutProperty('bounds-events', 'visibility', 'none');
      }

      if(map.getSource('click-events')) {
        map.getSource('click-events').setData(clickGeoJSON)
        map.setLayoutProperty('click-hex-fill', 'visibility', 'visible');
        map.setLayoutProperty('click-events', 'visibility', 'visible');
      } else {
        map.addSource('click-events', {
          type : 'geojson',
          data : clickGeoJSON
        })
        map.addLayer({
          id : 'click-events',
          source : 'click-events',
          type: 'circle'
        })
      }
      if(mapBounds) {
        var feature = { type : 'FeatureCollection', features : [{
          type : "Feature",
          properties : {},
          geometry : {
            type : "LineString",
            coordinates : [[mapBounds._sw.lng, mapBounds._sw.lat], [mapBounds._ne.lng, mapBounds._ne.lat]]
          }
        }]}
        var length = turf.length(feature);
        var bearing = turf.bearing(feature.features[0].geometry.coordinates[0], feature.features[0].geometry.coordinates[1]);
        var adjustedLineStart = turf.destination(feature.features[0].geometry.coordinates[0], length/10, 225);
        var adjustedLineEnd = turf.destination(feature.features[0].geometry.coordinates[1], length/10, 45);
        feature.features[0].geometry.coordinates[0] = adjustedLineStart.geometry.coordinates;
        feature.features[0].geometry.coordinates[1] = adjustedLineEnd.geometry.coordinates;
        // var bufferedFeature = turf.buffer(feature, length/10)
        var bounds = turf.bbox(feature)
        var hexGrid = turf.hexGrid(bounds, length/50);
        var maxClicks = 0;
        var minClicks = 999;
        hexGrid.features.forEach(hex => {
          var pointsInPoly = turf.pointsWithinPolygon(clickGeoJSON, hex);
          hex.properties.count = pointsInPoly.features.length;
          if(hex.properties.count > maxClicks) {
            maxClicks = hex.properties.count
          }
          if(hex.properties.count < minClicks) {
            minClicks = hex.properties.count
          }
        })
        setMinNumberMap(minClicks)
        setMaxNumberMap(maxClicks)
        if(minClicks !== 999 && maxClicks !== 0) {
          if(map.getSource('click-hex')) {
            map.getSource('click-hex').setData(hexGrid)
            map.setPaintProperty('click-hex-fill', 'fill-color', [
              'interpolate',
              ['linear'],
              ['get', 'count'],
              minClicks,
              'blue',
              maxClicks*0.2,
              'royalblue',
              maxClicks*0.4,
              'cyan',
              maxClicks*0.6,
              'lime',
              maxClicks*0.8,
              'yellow',
              maxClicks,
              'red'
            ])
          } else {
            map.addSource('click-hex', {
              type : 'geojson',
              data : hexGrid
            })
            map.addLayer({
              id : 'click-hex-fill',
              source : 'click-hex',
              type: 'fill',
              paint : {
                'fill-outline-color' : 'rgba(0,0,0,0)',
                'fill-color' : [
                  'interpolate',
                  ['linear'],
                  ['get', 'count'],
                  minClicks,
                  'blue',
                  maxClicks*0.2,
                  'royalblue',
                  maxClicks*0.4,
                  'cyan',
                  maxClicks*0.6,
                  'lime',
                  maxClicks*0.8,
                  'yellow',
                  maxClicks,
                  'red'
                ],
                'fill-opacity' : 0.2
              }
            })
          }
        }
      }
    }

    // A few buttons: for click heatmap, bounds/view heatmap, load heatmap
    // Need to basically define viewed area, get bounds and split into a grid, then color the grid and also place the specific dots

    return (
      <div className="flex flex-col col-span-full xl:col-span-12 bg-white shadow-lg rounded-sm border border-gray-200">
        <header className="px-5 py-4 border-b border-gray-100 flex items-center">
          <h2 className="font-semibold text-gray-800">Heatmap</h2>
          <div style={{textAlign: 'right', width: '100%'}} className="legend-swatches">
            <div style={{display:'inline-block', width: 12, height: 12, backgroundColor: 'blue'}} /> {minNumberMap}
            <div style={{display:'inline-block', width: 12, height: 12, backgroundColor: 'royalblue', marginLeft: '20px'}} /> {(maxNumberMap * 0.2).toFixed(1)}
            <div style={{display:'inline-block', width: 12, height: 12, backgroundColor: 'cyan', marginLeft: '20px'}} /> {(maxNumberMap * 0.4).toFixed(1)}
            <div style={{display:'inline-block', width: 12, height: 12, backgroundColor: 'lime', marginLeft: '20px'}} /> {(maxNumberMap * 0.6).toFixed(1)}
            <div style={{display:'inline-block', width: 12, height: 12, backgroundColor: 'yellow', marginLeft: '20px'}} /> {(maxNumberMap * 0.8).toFixed(1)}
            <div style={{display:'inline-block', width: 12, height: 12, backgroundColor: 'red', marginLeft: '20px'}} /> {maxNumberMap}
          </div>
        </header>
        <div>
          <div id="map" style={{width: '100%', height: '400px'}}/>
        </div>
        <div style={{margin: 10, textAlign: 'right'}}>
          <button onClick={() => setType('clicks')} style={{marginRight: 20}} className={`btn bg-gray-${type === 'clicks' ? '700' : '400'} hover:bg-gray-600 text-white`}>
            <span className="hidden xs:block">Clicks</span>
          </button>
          <button onClick={() => setType('bounds')} className={`btn bg-gray-${type === 'bounds' ? '700' : '400'} hover:bg-gray-600 text-white`}>
            <span className="hidden xs:block">Viewed Areas</span>
          </button>
        </div>
      </div>
    );
  }

  export default AnalyticsMap;
