import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Sidebar from '../../partials/Sidebar';
import Header from '../../partials/Header';
import MapsSidebar from '../../partials/maps/MapsSidebar'
import ListMapsPanel from '../../partials/maps/ListMapsPanel';

import { getMaps, deleteMap } from '../../actions/actions_maps';

function MapsList({ dispatch, allMaps, currentAccount }) {

  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    if(currentAccount) {
      dispatch(getMaps(currentAccount.id))
    }
  }, [currentAccount])

  const removeMap = (map_id) => {
    if(window.confirm("Are you sure you want to delete this map? This will delete all relevant statistics.")) {
      dispatch(deleteMap(map_id, currentAccount.id));
    }
  }

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="mb-8">
              {/* Title */}
              <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">Map Tracking Settings</h1>
            </div>

            {/* Content */}
            <div className="bg-white shadow-lg rounded-sm mb-8">
              <div className="flex flex-col md:flex-row md:-mr-px">
                <MapsSidebar />
                <ListMapsPanel allMaps={allMaps} removeMap={removeMap} />
              </div>
            </div>

          </div>
        </main>

      </div>

    </div>
  );
}

const mapsState = (state)=>({
  allMaps: state.maps.allMaps,
  currentAccount : state.account.currentAccount
})

export default connect(mapsState)(MapsList)
