import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import SearchForm from '../partials/actions/SearchForm';
import DeleteButton from '../partials/actions/DeleteButton';
import DateSelect from '../components/DateSelect';
import Datepicker from '../components/Datepicker';
import FilterButton from '../components/DropdownFilter';
import SessionsTable from '../partials/sessions/SessionsTable';
import PaginationClassic from '../components/PaginationClassic';

import { getSessions } from '../actions/actions_sessions';

function Sessions({ dispatch, allSessions, startTime, endTime, currentAccount, totalSessions }) {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if(currentAccount) {
      dispatch(getSessions(currentAccount.id, startTime, endTime, currentPage ))
    }
  }, [currentAccount])

  useEffect(() => {
    if(currentAccount) {
      dispatch(getSessions(currentAccount.id, startTime, endTime, currentPage ))
    }
  }, [currentPage, currentAccount, startTime, endTime])

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">

              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">Map Sessions</h1>
                <p>Click on session IDs to view more information about a single user experience. IDs are generated randomly.</p>
              </div>
              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Dropdown */}
                {/* <DateSelect /> */}

                {/* Datepicker built with flatpickr */}
                <Datepicker align="right" />

              </div>

            </div>

            {/* More actions */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">

              {/* Left side */}
              {/* <div className="mb-4 sm:mb-0">
                <ul className="flex flex-wrap -m-1">
                  <li className="m-1">
                    <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-indigo-500 text-white duration-150 ease-in-out">All <span className="ml-1 text-indigo-200">67</span></button>
                  </li>
                  <li className="m-1">
                    <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-gray-200 hover:border-gray-300 shadow-sm bg-white text-gray-500 duration-150 ease-in-out">Paid <span className="ml-1 text-gray-400">14</span></button>
                  </li>
                  <li className="m-1">
                    <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-gray-200 hover:border-gray-300 shadow-sm bg-white text-gray-500 duration-150 ease-in-out">Due <span className="ml-1 text-gray-400">34</span></button>
                  </li>
                  <li className="m-1">
                    <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-gray-200 hover:border-gray-300 shadow-sm bg-white text-gray-500 duration-150 ease-in-out">Overdue <span className="ml-1 text-gray-400">19</span></button>
                  </li>
                </ul>
              </div> */}

              {/* Right side */}

            </div>

            {/* Table */}
            <SessionsTable allSessions={allSessions} />

            {/* Pagination */}
            <div className="mt-8">
              <PaginationClassic total={totalSessions} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            </div>

          </div>
        </main>

      </div>

    </div>
  );
}

const userState = (state)=>({
  allSessions: state.sessions.allSessions,
  totalSessions: state.sessions.totalSessions,
  startTime: state.date.startTime,
  endTime: state.date.endTime,
  currentAccount : state.account.currentAccount
})

export default connect(userState)(Sessions)
