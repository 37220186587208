const { SET_USERS, SET_ACTIVE_USERS, SET_USER_ANALYTICS } = require("../actions/actions_users");

const initialState = {
    allUsers : [],
    activeUsers : [],
    userAnalytics : false,
    totalUsers : false
}

const reducer_users = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_USERS:
            var newState = JSON.parse(JSON.stringify(state))
            newState.allUsers = payload.users;
            newState.totalUsers = payload.totalUsers
            return newState
        case SET_USER_ANALYTICS:
            var newState = JSON.parse(JSON.stringify(state))
            newState.userAnalytics = payload;
            return newState
        case SET_ACTIVE_USERS:
            var newState = JSON.parse(JSON.stringify(state))
            newState.activeUsers = payload;
            return newState
        default:
            return state
    }
}
export default reducer_users;
