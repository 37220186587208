const API_URL = process.env.REACT_APP_URL;
export const SET_USERS = 'SET_USERS';
export const SET_USER_ANALYTICS = 'SET_USER_ANALYTICS';
export const SET_ACTIVE_USERS = 'SET_ACTIVE_USERS';

export function getUsers(account_id, startTime, endTime, page) {
    return (dispatch) => {
        return fetch(`${API_URL}/api/users/list/${account_id}/${page}/?startTime=${startTime}&endTime=${endTime}`)
        .then(resp => resp.json()).then(response => {
            dispatch(setUsers(response))
        })
        .catch((error) => console.log("USER LIST ERROR: ", error));
    };
}

export function getUserAnalytics(user_id, startTime, endTime) {
    return (dispatch) => {
        return fetch(`${API_URL}/api/users/analytics/${user_id}/?startTime=${startTime}&endTime=${endTime}`)
        .then(resp => resp.json()).then(response => {
          // console.log(response)
            dispatch(setUserAnalytics(response.analytics))
        })
        .catch((error) => console.log("USER LIST ERROR: ", error));
    };
}

export function getActiveUsers(map_id, account_id) {
    return (dispatch) => {
        var query = `${account_id}`;
        if(map_id) {
          query += `/${map_id}`;
        } else {
          query += `/none`;
        }
        return fetch(`${API_URL}/api/users/list-active/${query}`)
        .then(resp => resp.json()).then(response => {
            dispatch(setActiveUsers(response.users))
        })
        .catch((error) => console.log("USER ACTIVE ERROR: ", error));
    };
}

export function setUsers(payload){
    return {
        type: SET_USERS,
        payload: payload
    };
}

export function setUserAnalytics(payload){
    return {
        type: SET_USER_ANALYTICS,
        payload: payload
    };
}

export function setActiveUsers(payload){
    return {
        type: SET_ACTIVE_USERS,
        payload: payload
    };
}
