import React, { useState, useEffect } from 'react';
import LineChart from '../../charts/LineChart04';
import { Link } from 'react-router-dom';

// Import utilities
import { tailwindConfig, hexToRGB } from '../../utils/Utils';

function AnalyticsCard02({ activeUsers }) {

  const [ activeUserData, setActiveUserData ] = useState([]);
  const [ usersByLocation, setUsersByLocation ] = useState([]);

  useEffect(() => {
    var newActiveUserData = JSON.parse(JSON.stringify(activeUserData))
    newActiveUserData.push({
      time : new Date().getTime(),
      users : activeUsers.length
    })
    if(newActiveUserData.length > 10) {
      newActiveUserData.shift()
    }
    setActiveUserData(newActiveUserData)

    var newUsersByLocation = [];
    activeUsers.forEach(user => {
      var userIndex = newUsersByLocation.findIndex(thisUser => thisUser.location === user.ipdata.city);
      if(userIndex > -1) {
        newUsersByLocation[userIndex].count += 1;
      } else {
        newUsersByLocation.push({
          location : user.ipdata.city,
          count : 1
        })
      }
    })
    setUsersByLocation(newUsersByLocation)
  }, [activeUsers])

  const chartData = {
    labels: activeUserData && activeUserData.map(data => data.time),
    datasets: [
      // Indigo line
      {
        data: activeUserData && activeUserData.map(data => data.users),
        fill: true,
        backgroundColor: `rgba(${hexToRGB(tailwindConfig().theme.colors.blue[500])}, 0.08)`,
        borderColor: tailwindConfig().theme.colors.indigo[500],
        borderWidth: 2,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
        clip: 20,
      },
    ],
  };

  return (
    <div className="flex flex-col col-span-full xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">Active Users Right Now</h2>
      </header>
      {/* Card content */}
      <div className="flex flex-col h-full">
        {/* Live visitors number */}
        <div className="px-5 py-3">
          <div className="flex items-center">
            {/* Red dot */}
            <div className="relative flex items-center justify-center w-4 h-4 rounded-full bg-red-100 mr-3" aria-hidden="true">
              <div className="absolute w-1.5 h-1.5 rounded-full bg-red-500"></div>
            </div>
            {/* Vistors number */}
            <div>
              <div className="text-3xl font-bold text-gray-800 mr-2">{activeUsers.length}</div>
              <div className="text-sm text-gray-500">Live visitors</div>
            </div>
          </div>
        </div>

        {/* Chart built with Chart.js 3
        <div >
          <LineChart data={chartData} width={389} height={70} />
        </div> */}

        {/* Table */}
        <div className="flex-grow px-5 pt-3 pb-1"  style={{maxHeight: 350, overflowY: 'auto'}}>
          <div className="overflow-x-auto">
            <table className="table-auto w-full">
              {/* Table header */}
              <thead className="text-xs uppercase text-gray-400">
                <tr>
                  <th className="py-2">
                    <div className="font-semibold text-left">Top Locations</div>
                  </th>
                  <th className="py-2">
                    <div className="font-semibold text-right">Active users</div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              <tbody className="text-sm divide-y divide-gray-100">
                {/* Row */}
                {usersByLocation.map(user => {
                  return (
                    <tr>
                      <td className="py-2">
                        <div className="text-left">{user.location}</div>
                      </td>
                      <td className="py-2">
                        <div className="font-medium text-right text-gray-800">{user.count}</div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>

        {/* Card footer */}
        {/* <div className="text-right px-5 pb-4">
          <Link className="text-sm font-medium text-indigo-500 hover:text-indigo-600" to="#0">Real-Time Report -&gt;</Link>
        </div> */}
      </div>
    </div>
  );
}

export default AnalyticsCard02;
